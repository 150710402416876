<template>
  <v-row id="abstract-section">
    <v-col cols="12">
      <v-row cols="12">
        <v-col cols="2">
          <p class="plain-title">ABSTRACT</p>
        </v-col>
        <v-col cols="10">
          <Transition>
            <v-textarea
              v-if="!isLoadingAbstract"
              label=""
              v-model="abstract"
              :disabled="!article.title"
              variant="outlined"
            ></v-textarea>
          </Transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"></v-col>

        <v-col cols="8" class="float-right" v-if="article.abstract && !isLoadingAbstract && !isPreviewPage">
          <v-btn @click="approveAbstract" class="plain-btn inline inverse">
            <span>CONTINUE<v-icon>mdi:mdi-arrow-right</v-icon> </span>
          </v-btn>
          <v-btn @click="refreshAbstract" class="plain-btn inline">
            <span>
              <span>TRY AGAIN</span>
              <v-icon>fa-solid fa-rotate</v-icon>
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <wait-animation
      v-if="isLoadingAbstract"
      class="center"
      singleColor
      message="We are getting the abstract ready"
      :height="60"
      :width="60"
    />
  </v-row>
</template>
<style scoped>
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.inverse {
  background-color: #8200ff;
  color: white !important;
}

.label {
  margin-bottom: 20px;
}

.btn-title-refresh {
  margin-bottom: 40px;
  margin-top: -20px !important;
}
</style>
<script lang="ts" setup>
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import VueScrollTo from "vue-scrollto";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import { computed } from "vue";

const route = useRoute();
const store = useStore();

const approveAbstract = () => {
  store.dispatch("article/approveArticleAbstract", true);
  scrollTo("#image-section");
};
const scrollTo = (section: string) => VueScrollTo.scrollTo(section);
const article = computed(() => store.state.article);
const abstract = computed({
  get() {
    return store.state.article.abstract ?? "";
  },
  set(newAbstract: string) {
    store.dispatch("article/setAbstract", newAbstract);
  },
});
const refreshAbstract = () => {
  store.dispatch("article/approveArticleAbstract", false);
  store.dispatch("article/generateAbstractForTitle", { title: article.value.title });
};

const isLoadingAbstract = computed(() => store.state.loading.loadingAbstract);

const isPreviewPage = computed(() => route.path.includes("/preview"));
</script>
