<template>
  <div class="integration-grid">
    <div class="paper-background">
      <div cols="4" class="integration-box">
        <h2 class="integration-title">Google Integration</h2>
        <div class="integration-content">
          <p class="integration-description">
            Integrate your Google Ads/Google Analytics and Google Search Console accounts to access various
            features and functionalities.
          </p>
          <div v-if="!isAuthenticated && !sites.length > 0" class="auth-div">
            <a :href="getAdminTokenUpdateUrl()" target="_blank">
              <img
                class="auth-button"
                :src="`${publicPath}images/google_signin_dark2.png`"
                alt="Google Logo"
              />
            </a>
          </div>
          <div v-else class="authenticated">
            <div v-if="isAuthenticated">
              <h3 class="authenticated-title">Authenticated Successfully!</h3>
              <a :href="getAdminTokenUpdateUrl()" target="_blank">
                <v-btn class="plain-btn">
                  <v-icon>mdi:mdi-sync</v-icon>
                  <span>Reauthenticate</span>
                </v-btn>
              </a>
            </div>
            <p class="account-info">Company has access to:</p>
            <v-row cols="12" class="scopes">
              <v-col cols="4">
                <div :class="`${userHasGADS ? '' : 'disabled'}  scope maire-drop-shadow padding-sm`">
                  <img
                    src="https://www.gstatic.com/images/branding/product/2x/ads_48dp.png"
                    alt="Keyword Icon"
                    class="scope-icon"
                  />
                  <span>Google Ads</span>
                </div>
              </v-col>
              <v-col cols="4">
                <div :class="`${userHasGA ? '' : 'disabled'} scope maire-drop-shadow padding-sm`">
                  <img
                    src="https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg"
                    alt="Keyword Icon"
                    class="scope-icon"
                  />
                  <span>Google Analytics</span>
                  <div class="site-select">
                    <v-select
                      v-model="selectedProperty"
                      :disabled="!isGAAuthorizer"
                      :items="properties"
                      variant="outlined"
                      item-value="property_id"
                      item-title="nameAndId"
                      label="Select a property"
                      return-object
                      dense
                    >
                    </v-select>
                    <div v-if="!isGAAuthorizer" class="no-sites">
                      <p style="text-align: center">
                        Authorized by another user:
                        {{ defaultGAUser }}. Please contact them to change the site.
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div :class="`${userHasGSC || sites ? '' : 'disabled'} scope maire-drop-shadow padding-sm`">
                  <img :src="`${publicPath}images/gsc_logo.png`" alt="Keyword Icon" class="scope-icon" />
                  <span>Google Search Console</span>
                  <div class="site-select">
                    <v-select
                      v-model="selectedSite"
                      :disabled="!isGSCAuthorizer"
                      :items="sites"
                      item-title="site_url"
                      variant="outlined"
                      item-value="site_url"
                      label="Select a site"
                      dense
                    >
                    </v-select>
                    <div v-if="!isGSCAuthorizer" class="no-sites">
                      <p style="text-align: center">
                        Authorized by another user:
                        {{ defaultGSCUser }}. Please contact them to change the site.
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="scopes"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 50%;
}

.integration-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

h2.integration-title {
  padding: 0px;
}

.disabled {
  filter: grayscale(100%);
}

.integration-box {
  border-radius: 8px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.integration-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.integration-content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.integration-description {
  margin-bottom: 20px;
  text-align: center;
}

.auth-div {
  margin-bottom: 20px;
}

.auth-div img {
  width: 25%;
}
.authenticated-title {
  font-size: 18px;
  text-align: center;
}

.account-info {
  margin-bottom: 10px;
  text-align: center;
}

.scopes {
  display: flex;
  justify-content: center;
}

.scope {
  align-items: top;
  min-height: 180px;
}

.site-select {
  margin-top: 12px !important;
}

.scope-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
</style>

<script lang="ts">
import { BACKEND_PATH } from "@/helpers";
import { defineComponent } from "vue";
import { useStore } from "@/store"

export default defineComponent({
  name: "GoogleIntegrationWidget",
  data: () => ({
    selectedSite: undefined as undefined | string,
    selectedProperty: undefined as undefined | string,
  }),
  setup() {
    const store = useStore();
    return { store }
  },
  mounted() {
    if (!this.store.state.gsc.gsc_sites?.length) {
      this.store.dispatch("gsc/fetch_gsc_sites");
    } else {
      this.selectedSite = this.defaultSite?.site_url;
    }
    if (!this.store.state.ga_properties?.length) {
      this.store.dispatch("fetch_ga_properties");
    } else {
      this.selectedProperty = this.defaultProperty?.property_id;
    }
  },
  watch: {
    selectedSite() {
      if (this.selectedSite !== this.defaultSite?.site_url) {
        this.store.dispatch("gsc/clear_gsc_performance_data");
        this.store.dispatch("gsc/select_gsc_site", this.selectedSite);
      }
    },
    selectedProperty() {
      if (this.selectedProperty !== this.defaultProperty?.property_id) {
        this.store.dispatch("select_ga_property", this.selectedProperty);
      }
    },
    defaultSite() {
      this.selectedSite = this.defaultSite?.site_url;
    },
    defaultProperty() {
      this.selectedProperty = this.defaultProperty?.property_id;
    },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
    isAuthenticated() {
      return !!this.store.state.user.google_auth;
    },
    scopes() {
      return this.store.state.user.google_auth?.scope.split(",");
    },
    userHasGSC() {
      return this.store.state.user.google_auth?.scope?.includes(
        "https://www.googleapis.com/auth/webmasters.readonly"
      );
    },
    userHasGA() {
      return this.store.state.user.google_auth?.scope?.includes(
        "https://www.googleapis.com/auth/analytics.readonly"
      );
    },
    userHasGADS() {
      return this.store.state.user.google_auth?.scope?.includes("https://www.googleapis.com/auth/adwords");
    },
    sites() {
      return this.store.state.gsc.gsc_sites;
    },
    properties() {
      return this.store.state.ga_properties.map((property) => ({
        ...property,
        nameAndId: `${property.name} (${property.property_id})`,
      }));
    },
    defaultSite() {
      return this.store.state.gsc.gsc_sites?.find((site) => site.is_default);
    },
    defaultProperty() {
      return this.store.state.ga_properties?.find((property) => property.is_default);
    },
    defaultGSCUser() {
      return this.defaultSite?.user?.email;
    },
    defaultGAUser() {
      return this.defaultProperty?.user?.email;
    },
    isGAAuthorizer() {
      if (!this.defaultGAUser && this.properties?.length > 0) {
        return this.properties?.[0]?.user?.email === this.store.state.user?.email;
      } else {
        return this.defaultGAUser === this.store.state.user?.email;
      }
    },
    isGSCAuthorizer() {
      if (!this.defaultGSCUser && this.sites?.length > 0) {
        return this.sites?.[0]?.user.email === this.store.state.user?.email;
      } else {
        return this.defaultGSCUser === this.store.state.user?.email;
      }
    },
  },
  methods: {
    getAdminTokenUpdateUrl() {
      return `${BACKEND_PATH}/update_token`;
    },
  },
});
</script>
