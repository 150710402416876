<template>
  <div class="home">
    <v-row cols="12">
      <v-col cols="1" class="inline" :key="image" v-for="image in images">
        <ImageSelectorImage :image="image" />
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
img {
  height: 150px;
  padding: 10px;
}
</style>

<script>
// @ is an alias to /src

import ImageSelectorImage from "@/components/ArticleGeneration/ImageSelector/ImageSelectorImage.vue";

export default {
  name: "AdminView",

  computed: {
    images() {
      return this.store.article.images;
    },
  },
  components: { ImageSelectorImage },
};
</script>
