<template>
  <v-row id="titleselector" :class="`${article.loadingTitleSuggestions ? 'loading' : ''}`">
    <v-col v-if="showSideBar" cols="2">
      <p class="plain-title">TITLE</p>
    </v-col>
    <v-col :cols="showSideBar ? 10 : 12">
      <Transition>
        <div
          id="titleselector-radio-group-container"
          v-if="
            article.titleSuggestions &&
            (!title || titleEditOpen || !showSideBar) &&
            !article.loadingTitleSuggestions
          "
          class="maire-dark-blue"
        >
          <v-radio-group v-model="title" id="titleselector-radio-group">
            <v-radio
              @click="() => scrollTo('#abstract-section')"
              class="title-radio-button maire-blue"
              :label="suggestion"
              :value="suggestion"
              :key="(suggestion as string)"
              v-for="suggestion in article.titleSuggestions"
            >
              <template v-slot:label>
                <strong class="maire-blue">{{ suggestion }}</strong>
              </template>
            </v-radio>
          </v-radio-group>
          <v-btn @click="refreshTitleSuggestions" class="plain-btn inline btn-title-refresh">
            <span>
              <span>TRY AGAIN</span>
              <v-icon>mdi:mdi-refresh</v-icon>
            </span>
          </v-btn>
          <div v-if="showCustomTitle">
            <p class="label">Or write your own title and press enter</p>
            <v-text-field
              id="custom-title"
              v-model="customTitle"
              class="maire-dark-blue"
              density="compact"
              variant="outlined"
            />
            <v-btn
              v-if="titleEditOpen && showSideBar"
              @click="editTitle(false)"
              class="plain-btn inline btn-title-refresh"
            >
              <span>BACK</span>
              <v-icon>mdi:mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </Transition>
      <div v-if="title && !titleEditOpen && showSideBar">
        <v-row>
          <v-col cols="9">
            <v-select :placeholder="title" :items="titleOptions" v-model="title" variant="outlined" />
          </v-col>
          <v-col cols="3">
            <v-btn @click="editTitle" class="plain-btn inline">
              <span>
                <v-icon>mdi:mdi-pencil-outline</v-icon>
                <span>EDIT</span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <input
        type="text"
        v-on:change="saveTitle"
        class="inline subtitle-edit"
        variant="outlined"
        v-if="customizedTitle"
        :value="article.title"
      />
    </v-col>
    <WaitAnimation
      v-if="article.loadingTitleSuggestions || (!article.title && !article.titleSuggestions)"
      class="center"
      singleColor
      message="We are getting your titles ready"
      :height="60"
      :width="60"
    />
  </v-row>
</template>
<style scoped>
/* .plain-btn {
    margin-left: 0px;
} */

.loading {
  min-height: 200px;
}
/* 
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
} */

.wait-animation {
  height: 100%;
  margin-top: -50px;
}
#titleselector {
  padding-top: 20px;
}
.label {
  margin-bottom: 20px;
}

.btn-title-refresh {
  margin-bottom: 20px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 20px;
}
</style>
<script lang="ts">
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { defineComponent } from "vue";
import VueScrollTo from "vue-scrollto";
import { useStore } from "@/store"
export default defineComponent({
  props: {
    alreadyOpened: { type: Boolean, default: false },
    skipNextSteps: { type: Boolean, default: false },
    sidebar: { type: Boolean, default: true },
    allowCustom: { type: Boolean, default: true },
  },
  data() {
    return {
      el: "#titleselector",
      customTitle: "",
      customizedTitle: false,
      titleEditOpen: this.alreadyOpened,
      showSideBar: this.sidebar,
      showCustomTitle: this.allowCustom,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  watch: {
    title(value: string) {
      this.titleEditOpen = false;
      if (value) {
        // this.store.dispatch("article/selectArticleTitle", { title: value });
        if (!this.skipNextSteps) {
          this.store.dispatch("article/generateAbstractForTitle", { title: value });
        }
        this.store.dispatch("article/generateImagePromptSuggestions", value);
      }
    },
  },
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      this.handleKeyboardEvent(event);
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  computed: {
    article() {
      return this.store.state.article;
    },
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    title: {
      get() {
        return this.store.state.article.title;
      },
      set(title: string) {
        this.store.dispatch("article/selectTitle", title);
      },
    },
    titleOptions() {
      if (this.customTitle) {
        return this.store.state.article.titleSuggestions?.concat(this.customTitle);
      } else {
        return this.store.state.article.titleSuggestions;
      }
    },
  },
  methods: {
    saveTitle(e: any) {
      this.customizedTitle = false;
      this.store.dispatch("article/selectTitle", e.target.value);
    },
    editTitle(opened: boolean = true) {
      this.titleEditOpen = opened;
    },
    scrollTo(section: string) {
      VueScrollTo.scrollTo(section);
    },
    refreshTitleSuggestions() {
      if (this.keyword?.keyword) {
        this.store.dispatch("article/generateTitleSuggestions", {
          keyword: this.keyword?.keyword,
          keepArticle: this.skipNextSteps,
        });
      }
    },
    handleKeyboardEvent(event: KeyboardEvent) {
      if (this.customTitle && event.code === "Enter" && (event as any).target?.id === "custom-title") {
        if (this.title != this.customTitle) {
          this.title = this.customTitle;
          this.scrollTo("#abstract-section");
        }
      }
    },
  },
  components: { WaitAnimation },
});
</script>
