<template>
  <div class="main">
    <div class="content maire-drop-shadow padding-lg">
      <h2>Do you want to work with an existing keyword or start from scratch?</h2>
      <p>
        You have saved some excellent keywords that Maire has found and analysed for you, you can skip
        straight to the creation part by selecting one of them from the dropdown below.
      </p>
      <div class="keyword_selector">
        <v-select
          v-model="selectedKeywordId"
          :items="analysisKeywords"
          item-title="keyword"
          item-value="id"
          label="Select existing keyword"
          variant="outlined"
        >
        </v-select>
      </div>
      <div id="divider"></div>
      <p>
        If you want to create content for something not discovered by Maire, you can start our creation
        process from scratch
      </p>
      <div class="button-container">
        <v-btn class="plain-btn" @click="startFromScratch">Start creating from scratch</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#divider {
  width: 100%;
  height: 2px;
  background-color: rgba(var(--v-theme-mairePurple));
  margin-top: 0px;
  margin-bottom: 24px;
}

.button-container {
  padding-top: 12px;
}

.keyword_selector {
  padding-top: 24px;
}

.content {
  text-align: center;
  margin-left: 30%;
  margin-right: 30%;
  background-color: white;
}

.content p {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.plain-btn {
  border-radius: 15px;
}
</style>

<script lang="ts">
import { useStore } from "@/store";
import { Keyword } from "@/store/modules/keywords/types";
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  name: "ContentCreationView",
  data() {
    return {
      selectedKeywordId: undefined as number | undefined,
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const analysisKeywords = computed(() => {
      const keywords = store.state.keywords.analysisKeywords;
      return keywords
        .sort((a: Keyword, b: Keyword) => {
          return a.keyword.localeCompare(b.keyword);
        })
        .map((keyword: Keyword) => {
          return {
            keyword: `${keyword.keyword} | volume: ${keyword.volume} | ${keyword.localization.location_name} (${keyword.localization.language_code})`,
            id: keyword.id,
          };
        });
    })
    return { store, route, router, analysisKeywords };
  },
  watch: {
    selectedKeywordId() {
      if (this.selectedKeywordId) {
        this.store.dispatch("clearKeywordWithDetails");
        this.router.push(`/keywords/${this.selectedKeywordId}/article`);
      }
    },
  },
  methods: {
    startFromScratch() {
      this.router.push("/content/new");
    },
  },
});
</script>
