<template>
  <div :class="`chart ${loading ? 'loading' : ''}`" v-show="options" style="position: relative; height: 100%">
    <apexchart
      height="400"
      ref="chart"
      :options="options"
      :series="series"
      @legendClick="handleClick"
    ></apexchart>
  </div>
</template>
<style scoped>
.waiting_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  padding: 13%;
}
span {
  background-color: rgb(255, 176, 176);
  margin-top: 70px;
  padding: 10px;
}
.loading {
  filter: blur(4px) brightness(80%);
}
.chart {
  transition: filter 0.5s ease-in-out;
}
</style>
<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref, Ref, watch, PropType } from "vue";
import { VueApexChartsComponent } from "vue3-apexcharts";
import { Label } from "@/store/modules/labels/types";
import { useLabelColor } from "@/composables/useLabelColor";
const helpers = useLabelColor();
export interface VolumeDataPoint {
  date_from: Date;
  monthly_searches: number;
  is_forecast?: boolean;
}
const emit = defineEmits(["unselect"]);
const props = defineProps({
  stats: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  labels: {
    type: Object as PropType<Record<string, Label>>,
    required: true,
  },
});
const chart = ref(null);
const series: Ref<{ name: string; type: string; color: string; data: any }[]> = ref([]);
const options = ref(null);
function calculateSeries() {
  return Object.entries(props.stats || {})
    ?.map(([key, value]) => {
      if (value?.prediction) {
        return {
          name: `${key} (with prediction)`,
          type: "line",
          color: helpers.getLabelColor(key),
          data:
            value.prediction?.map((x: any) => ({
              x: isNaN(x["date_from"]) ? Date.parse(x["date_from"]) : x["date_from"],
              y: x["monthly_searches"],
              forecasted: x.is_forecast,
            })) ?? [],
        };
      } else {
        return {
          name: key,
          type: "line",
          color: helpers.getLabelColor(key),
          data:
            value?.volumes?.map((x: VolumeDataPoint) => ({ x: x.date_from, y: x.monthly_searches })) ?? [],
        };
      }
    })
    .filter((x) => x?.data?.length > 0);
}
const createAnnotationOptions = (srs: any[] | undefined) => {
  const predictionData = (srs ?? series.value)
    .filter((x: any) => x.name.includes("prediction"))?.[0]
    ?.data?.filter((x: any) => x.forecasted);
  const firstDayOfForecast = new Date(predictionData?.[0]?.x).getTime();
  const lastDayOfForecast = new Date(predictionData?.slice(-1)?.[0]?.x).getTime();
  return {
    xaxis: lastDayOfForecast
      ? [
          {
            x: firstDayOfForecast,
            x2: lastDayOfForecast,
            fillColor: "#8200FF",
            borderColor: "#8200FF",
            opacity: 0.05, // Adjust the opacity to your preference
            label: { text: "Forecasting period", style: { color: "#8200FF" } },
          },
        ]
      : undefined,
  };
};
const calculateAndDraw = () => {
  series.value = calculateSeries(); // tää on kans
  const annotations = createAnnotationOptions(series.value);
  const renderAnnotations = !!annotations?.xaxis?.length;
  chart.value.chart.updateOptions(
    {
      series: series.value,
      ...(renderAnnotations ? { annotations } : {}),
    },
    false,
    true,
    false
  );
};
watch(
  () => props.stats,
  () => {
    if (Object.keys(props.stats)) {
      if (chart.value) {
        calculateAndDraw();
      }
    }
  }
);
onMounted(() => {
  series.value = calculateSeries();
  options.value = calculateOptions();
  const el = chart.value as VueApexChartsComponent;
  chart.value = el;
});

const handleClick = (_event: any, index: any) => {
  const label = series.value?.[index]?.name;
  if (label) {
    emit("unselect", label);
  }
};

const calculateOptions = () => {
  return {
    chart: {
      id: "LabelVolumeChart",
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
    },
    yaxis: [
      {
        title: {
          text: "Searches / month",
        },
        seriesName: "Search volume",
        forceNiceScale: true,
        decimalsInFloat: 0,
        offsetY: 1000,
        min: 0,
      },
    ],
    tooltip: {
      shared: true,
      followCursor: true,
      x: {
          show: true,
          format: 'MMM yyyy',
          formatter: undefined,
      }
    },
  };
};
</script>
