<template>
  <v-row id="titleselector">
    <v-col :cols="12">
      <Transition>
        <div id="titleselector-radio-group-container" class="maire-dark-blue">
          <v-row>
            <v-col cols="10">
              <!-- <input style="background-color: white;color: grey;border-color: rgba(0,0,0,0.1)"
                                :placeholder="placeholder || 'Write your title here or select from the list that Maire generated'"
                                type="text" v-on:change="saveTitle" class="inline subtitle-edit" variant="outlined"
                                v-model="customizedTitle"> -->
              <v-text-field
                id="custom-title"
                :placeholder="
                  placeholder || 'Write your title here or select from the list that Maire generated'
                "
                v-model="customizedTitle"
                class="maire-dark-blue"
                density="compact"
                variant="outlined"
              />
            </v-col>
            <v-col cols="2">
              <v-btn @click="() => onSelect(customizedTitle)" v-if="refresh" class="plain-btn">
                <span>SAVE</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row cols="12">
            <WaitAnimation
              v-if="loading"
              class="wait-animation center"
              singleColor
              message="Maire is thinking for the best sections."
              :height="60"
              :width="60"
            />
            <v-radio-group
              v-model="selectedPrompt"
              v-if="!loading"
              :value="selected"
              density="comfortable"
              id="titleselector-radio-group"
            >
              <v-radio
                :onchange="(e: any) => onSelect(e.target.value)"
                class="title-radio-button maire-blue"
                :label="suggestion"
                :value="suggestion"
                :key="(suggestion as string)"
                v-for="suggestion in suggestions"
              >
                <template v-slot:label>
                  <strong class="maire-blue">{{ suggestion }}</strong>
                </template>
              </v-radio>
            </v-radio-group>
            <v-btn v-if="!refreshDisabled && refresh && !loading" class="plain-btn inline btn-title-refresh">
              <span @click="() => refresh && refresh()">
                <span>TRY AGAIN</span>
                <v-icon>mdi:mdi-refresh</v-icon>
              </span>
            </v-btn>
          </v-row>
        </div>
      </Transition>
    </v-col>
  </v-row>
</template>
<style scoped>
.wait-animation {
  height: 200px;
}

/* .plain-btn {
    margin-left: 0px;
} */

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.label {
  margin-bottom: 20px;
}

.btn-title-refresh {
  margin-bottom: 20px;
  margin-top: 20px !important;
  margin-left: 0px !important;
  margin-right: 20px;
}
</style>
<script lang="ts">
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { defineComponent } from "vue";
import VueScrollTo from "vue-scrollto";

export default defineComponent({
  props: {
    alreadyOpened: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    refreshDisabled: { type: Boolean, default: false },
    suggestions: { type: Array, default: () => [], required: true },
    placeholder: { type: String, required: false },
    onSelect: { type: Function, required: true },
    refresh: { type: Function },
    selected: { type: String, required: false },
  },
  data() {
    return {
      el: "#custom-title-selector",
      customTitle: "",
      customizedTitle: "",
      titleEditOpen: this.alreadyOpened,
      showSideBar: this.sidebar,
      showCustomTitle: this.allowCustom,
      retried: false,
      selectedPrompt: this.selected,
    };
  },
  mounted() {
    if (this.suggestions?.length === 0 && !this.retried) {
      this.retried = true;
      if (this.refresh) {
        this.refresh();
      }
    }
  },
  watch: {
    selected() {
      this.selectedPrompt = this.selected;
    },
    // customizedTitle(value: any) {
    //     this.onSelect(value)
    // }
  },
  methods: {
    saveTitle(e: any) {
      this.onSelect(e);
    },
    editTitle(opened: boolean = true) {
      this.titleEditOpen = opened;
    },
    scrollTo(section: string) {
      VueScrollTo.scrollTo(section);
    },
  },
  components: { WaitAnimation },
});
</script>
