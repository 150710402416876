import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaireTooltip = _resolveComponent("MaireTooltip")!

  return (_openBlock(), _createBlock(_component_MaireTooltip, null, {
    trigger: _withCtx(() => [
      _createElementVNode("span", {
        ref: "rowz",
        class: "hover-white"
      }, _toDisplayString(_ctx.volume), 513)
    ]),
    content: _withCtx(() => [
      _createElementVNode("span", null, [
        _createElementVNode("strong", null, "Latest volume from: " + _toDisplayString(_ctx.volumesFrom), 1),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.volumesByYear, (volumeData) => {
            return (_openBlock(), _createElementBlock("li", {
              key: volumeData.year
            }, [
              _createTextVNode(_toDisplayString(volumeData.year) + ": ", 1),
              _createElementVNode("strong", null, _toDisplayString(volumeData.volume), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}