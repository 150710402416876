<template>
  <MaireTooltip>
    <template #trigger>
      <span ref="rowz" class="hover-white">{{ volume }}</span>
    </template>
    <template #content>
      <span>
        <strong>Latest volume from: {{ volumesFrom }}</strong>
        <ul>
          <li :key="volumeData.year" v-for="volumeData in volumesByYear">
            {{ volumeData.year }}: <strong>{{ volumeData.volume }}</strong>
          </li>
        </ul>
      </span></template
    >
  </MaireTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireTooltip from "../MaireTooltip/MaireTooltip.vue";

export default defineComponent({
  name: "VolumeCell",
  props: {
    fieldValue: Number,
    item: Object,
  },
  computed: {
    volume() {
      if (this.fieldValue === null) return null;

      return numberFormatter(this.fieldValue || 0, 2);
    },
    volumesFrom() {
      if (!this.item?.volumes_from) {
        return "N/A";
      } else {
        const yearMonthString = this.item?.volumes_from;
        return `${yearMonthString.slice(4)}/${yearMonthString.slice(0, 4)}`;
      }
    },
    twoYearsAgo() {
      const currentYear = new Date().getFullYear();
      return (currentYear - 1).toString();
    },
    volumesByYear() {
      const volumeEntries = Object.entries(this.item?.volumes || {});
      let maxYear = 0;
      return volumeEntries.map(([year, volumes]) => {
        if (typeof Number(year) === "number" && Number(year) > maxYear) {
          maxYear = Number(year);
        }
        // @ts-ignore
        const volumesForYear: number = (volumes.filter(Boolean) as Array<number>).reduce(
          (acc: number, volume: number) => acc + volume,
          0
        );
        return {
          year: year === "proj" ? `${maxYear} Projected` : year,
          volume: numberFormatter(volumesForYear, 2),
        };
      });
    },
  },
  components: { MaireTooltip },
});
</script>
