<template>
  <v-row id="image-section">
    <v-col cols="12">
      <v-row>
        <v-col v-if="!hideSidebar" cols="2">
          <p class="plain-title">IMAGES</p>
        </v-col>
        <v-col :cols="hideSidebar ? '12' : '10'" v-if="!hideContent">
          <p v-if="(imagePromptEditOpen || !article.images) && !isLoadingImage">
            Select one of Maire's prompt suggestions
          </p>
          <!-- <p>Sky is the limit with our powerful image creation tool. However, to truly harness this power, you
                        need to
                        create an effective prompt. And that, is an art in itself. Need inspiration? Visit <a
                            href="https://dallery.gallery/the-dalle-2-prompt-book/" target="_blank">here.</a></p> -->
        </v-col>
      </v-row>
      <v-row v-if="!hideContent">
        <v-col cols="12" v-if="(imagePromptEditOpen || !article.images) && !isLoadingImage">
          <v-row>
            <v-col v-if="!hideSidebar" cols="2"></v-col>
            <v-col :cols="hideSidebar ? '12' : '10'">
              <!-- <v-text-field clearable :disabled="!article.abstractApproved && !isPreviewPage"
                                v-if="(!article.images || imagePromptEditOpen)" ref="types" variant="outlined"
                                v-model="imageContent" label="What's in the image?" cols="4"
                                class="image-dropddown mb-4" density="compact">
                            </v-text-field> -->
              <CustomTitleSelector
                :on-select="imagePromptSelect"
                :loading="isLoadingSuggestions"
                :selected="imageContent"
                :refresh-disabled="true"
                :already-opened="true"
                :placeholder="'Choose from below or write your own'"
                :suggestions="imagePromptSuggestions"
                :refresh="refreshImagePromptSuggestions"
              />
            </v-col>
          </v-row>
          <div class="prompt-chips">
            <v-row>
              <v-col v-if="!hideSidebar" cols="2"> </v-col>
              <v-col :cols="hideSidebar ? '12' : '10'">
                <p style="padding-top: 20px">
                  Add some spice to your image by providing keywords and pressing enter. You can pick some of
                  the ones Maire has thought of, or write anything you might think is needed for your specific
                  image
                </p>
                <v-text-field
                  :disabled="!article.abstractApproved && !isPreviewPage"
                  placeholder="Style, mood, type"
                  v-if="!isLoadingImage"
                  v-model="customStyle"
                  class="maire-dark-blue"
                  density="compact"
                  variant="outlined"
                  v-on:change="addCustomChip"
                />
                <v-chip
                  @click="(e: PointerEvent) => handleChipClick(e, style)"
                  :class="`ma-1 style-chip ${style.type} ${style.selected ? 'selected' : ''}`"
                  :key="style.name"
                  v-for="style in allStyles.sort((a: any, b: any) => a.type.localeCompare(b.type))"
                >
                  <v-icon style="margin-right: 5px">mdi:mdi-plus-circle</v-icon>{{ style.name }}
                  <v-icon @click="() => removeChip(style)" style="margin-left: 5px"
                    >mdi:mdi-close-circle</v-icon
                  >
                </v-chip>
              </v-col>
            </v-row>
          </div>
          <!-- <div class="prompt-chips">
                        <v-div :key="styleType" v-for="styleType in styleTypes">
                            <v-row>
                                <v-col cols="2">
                                    <p style="margin-left: 20px;">{{ styleType }}</p><br />
                                </v-col>
                                <v-col cols="10">
                                    <v-chip @click="handleChipClick"
                                        :class="`ma-1 style-chip ${style.selected ? 'selected' : ''}`" :key="style.name"
                                        v-for="style in allStyles.filter(stl => stl.type === styleType)">
                                        <v-icon style="margin-right: 5px">mdi:mdi-plus-circle</v-icon>{{ style.name }}
                                        <v-icon @click="handleChipClick"
                                            style="margin-left: 5px">mdi:mdi-close-circle</v-icon>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-div>
                    </div> -->
          <!-- <v-chip @click="handleChipClick"
                        :class="`ma-1 style-chip ${selectedStyles.includes(style) ? 'selected' : ''}`" :key="style"
                        v-for="style in allStyles">
                        <v-icon style="margin-right: 5px">mdi:mdi-plus-circle</v-icon>{{ style }}
                        <v-icon @click="handleChipClick" style="margin-left: 5px">mdi:mdi-close-circle</v-icon>
                    </v-chip> -->
        </v-col>
      </v-row>
      <v-row v-if="!hideContent">
        <v-col v-if="!hideSidebar" cols="2"></v-col>
        <v-col
          :cols="hideSidebar ? '12' : '10'"
          v-if="(imagePromptEditOpen || !article.images) && !isLoadingImage"
        >
          <p><strong>Prompt:</strong> "{{ getPrompt }}"</p>
          <br />
          <p>Or you can write your own prompt from scratch</p>
          <v-text-field
            :disabled="!article.abstractApproved && !isPreviewPage"
            :placeholder="imagePrompt"
            v-if="!isLoadingImage"
            v-model="imagePrompt"
            class="maire-dark-blue"
            density="compact"
            variant="outlined"
          />
        </v-col>
      </v-row>
      <wait-animation
        v-if="isLoadingImage"
        singleColor
        message="We are getting your image ready"
        :height="60"
        :width="60"
      />
      <Transition>
        <v-row
          class="images-row"
          justify="center"
          v-if="article.images && !imagePromptEditOpen && !isLoadingImage"
        >
          <v-col v-if="!hideSidebar" cols="2"></v-col>
          <v-col :cols="hideSidebar ? '12' : '10'">
            <v-row>
              <v-col cols="4" :key="image.imageSrc" v-for="image in article.images">
                <ImageSelectorImage
                  :image="image"
                  :removeImage="() => removeImage(image as unknown as Image)"
                />
              </v-col>
              <v-col @click="toggleImagePromptEdit" cols="4" v-if="article.images">
                <v-card class="ai-image image-placeholder" tile>
                  <v-icon class="ai-image-add">mdi:mdi-plus-circle</v-icon>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </Transition>
      <Transition>
        <v-row v-if="(!article.images || imagePromptEditOpen) && !isLoadingImage">
          <v-col cols="4"></v-col>
          <v-col cols="8" class="float-right">
            <v-btn
              v-if="article.abstractApproved || isPreviewPage"
              @click="generateImage"
              class="plain-btn inline imageButton inverse"
            >
              <span>GENERATE</span>
              <v-icon>mdi:mdi-cog</v-icon>
            </v-btn>
            <v-btn
              v-if="article.abstractApproved && !isPreviewPage && !article.images"
              @click="approveImages"
              class="plain-btn inline imageButton inverse"
            >
              <span>SKIP</span>
              <v-icon>mdi:mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn
              v-if="(article.abstractApproved || isPreviewPage) && article.images"
              @click="toggleImagePromptEdit"
              class="plain-btn inline imageButton"
            >
              <span>BACK</span>
              <v-icon>mdi:mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </Transition>
      <Transition>
        <v-row v-if="article.images && !imagePromptEditOpen && !isLoadingImage">
          <v-col cols="12" class="float-right">
            <v-btn
              v-if="article.abstractApproved && !isPreviewPage"
              @click="approveImages(true)"
              class="plain-btn inline imageButton inverse"
            >
              <span>CONTINUE</span>
              <v-icon>mdi:mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn
              v-if="article.abstractApproved || isPreviewPage"
              @click="generateImage"
              class="plain-btn inline imageButton"
            >
              <span>TRY AGAIN</span>
              <v-icon>mdi:mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="article.abstractApproved || isPreviewPage"
              @click="toggleImagePromptEdit"
              class="plain-btn inline imageButton"
            >
              <span>EDIT PROMPTS<v-icon>mdi:mdi-pencil-outline</v-icon></span>
            </v-btn>
          </v-col>
        </v-row>
      </Transition>
    </v-col>
  </v-row>
</template>
<style scoped>
.media {
  background-color: rgba(var(--v-theme-maireBlue), 0.15);
  color: rgba(var(--v-theme-maireBlue)) !important;
  border-color: rgba(var(--v-theme-maireBlue)) !important;
}

.theme {
  background-color: rgba(var(--v-theme-maireDarkBlue), 0.1);
  color: rgba(var(--v-theme-maireDarkBlue)) !important;
  border-color: rgba(var(--v-theme-maireDarkBlue)) !important;
}

.colors {
  background-color: rgba(var(--v-theme-mairePurple), 0.1);
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-color: rgba(var(--v-theme-mairePurple)) !important;
}

.artStyle {
  background-color: rgba(var(--v-theme-maireBlue), 0.05);
  color: rgba(var(--v-theme-maireBlue)) !important;
  border-color: rgba(var(--v-theme-maireBlue)) !important;
}

.technique {
  background-color: rgba(var(--v-theme-maireViolet), 0.1);
  color: rgba(var(--v-theme-maireViolet)) !important;
  border-color: rgba(var(--v-theme-maireViolet)) !important;
}

.quality {
  background-color: rgba(var(--v-theme-maireTeal), 0.1);
  color: rgba(var(--v-theme-maireTeal)) !important;
  border-color: rgba(var(--v-theme-maireTeal)) !important;
}

.mood {
  background-color: rgba(var(--v-theme-maireYellow), 0.1);
  color: rgba(var(--v-theme-maireYellow)) !important;
  border-color: rgba(var(--v-theme-maireYellow)) !important;
}

.background {
  background-color: rgba(var(--v-theme-mairePink), 0.1);
  color: rgba(var(--v-theme-mairePink)) !important;
  border-color: rgba(var(--v-theme-mairePink)) !important;
}

.prompt-chips {
  overflow: hidden;
  transition: all 0.2s ease-in;
  /* margin-top: -50px; */
}

.style-chip {
  color: rgba(var(--v-theme-maireBlue), 0.7);
  border: 1px solid rgb(var(--v-theme-maireBlue));
}

#image-section .selected {
  background-color: rgb(var(--v-theme-maireBlue));
  color: white !important;
  border: 1px solid rgb(var(--v-theme-maireBlue)) !important;
}

/* .plain-btn {
    margin-left: 12px;
} */

.images-row {
  margin-bottom: 20px;
}

.inverse {
  background-color: #8200ff;
  color: white !important;
}

.ai-image {
  text-align: center;
  width: 100%;
  height: 100%;
  border: 2px #b500ff solid;
  border-radius: 0px;
  background-color: rgba(229, 0, 255, 0.016);
  cursor: pointer;
}

.ai-image:hover {
  scale: 1.05;
}

.ai-image-add {
  position: relative;
  margin: auto;
  margin-top: 25%;
  color: #b500ff;
  cursor: pointer;
}

.image-placeholder {
  aspect-ratio: 1792/1024;
}
</style>
<script lang="ts">
import { Image } from "@/store/modules/article/types";
import { defineComponent } from "vue";
import VueScrollTo from "vue-scrollto";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import CustomTitleSelector from "../TitleSelector/CustomTitleSelector.vue";
import ImageSelectorImage from "./ImageSelectorImage.vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
export default defineComponent({
  props: {
    hideContent: { type: Boolean, default: false },
    hideSidebar: { type: Boolean, default: false },
  },
  data: () => ({
    imagePromptEditOpen: false,
    imagePrompt: "",
    imageContent: "",
    customStyle: "",
    allStyles: [
      { name: "Photograph", type: "media", selected: false },
      { name: "Pencil drawing", type: "media", selected: false },
      { name: "Watercolour", type: "media", selected: false },
      { name: "Charcoal", type: "media", selected: false },
      { name: "Crayon", type: "media", selected: false },
      { name: "Coloured pencil", type: "media", selected: false },
      { name: "Oil painting", type: "media", selected: false },
      { name: "3D render", type: "media", selected: false },
      { name: "Pixar", type: "theme", selected: false },
      { name: "Anime", type: "theme", selected: false },
      { name: "Cyberpunk", type: "theme", selected: false },
      { name: "Pastel colors", type: "colors", selected: false },
      { name: "Vibrant colors", type: "colors", selected: false },
      { name: "Graffiti", type: "artStyle", selected: false },
      { name: "Renessaince", type: "artStyle", selected: false },
      { name: "Fantasy", type: "theme", selected: false },
      { name: "Close-up", type: "technique", selected: false },
      { name: "Wide-angle", type: "technique", selected: false },
      { name: "Bokeh", type: "technique", selected: false },
      { name: "Wide angle lens", type: "technique", selected: false },
      { name: "Fish-eye", type: "technique", selected: false },
      { name: "Golden hour", type: "colors", selected: false },
      { name: "Blue hour", type: "colors", selected: false },
      { name: "Direct sun", type: "colors", selected: false },
      { name: "Cloudy weather", type: "colors", selected: false },
      { name: "Stark contrast", type: "artStyle", selected: false },
      { name: "Black and white", type: "colors", selected: false },
      { name: "8K", type: "quality", selected: false },
      { name: "Ultra-realistic", type: "quality", selected: false },
      { name: "Lomography", type: "artStyle", selected: false },
      { name: "Polaroid", type: "artStyle", selected: false },
      { name: "happy", type: "mood", selected: false },
      { name: "sad", type: "mood", selected: false },
      { name: "joyful", type: "mood", selected: false },
      { name: "mysterious", type: "mood", selected: false },
      { name: "cityscape", type: "background", selected: false },
      { name: "forest", type: "background", selected: false },
      { name: "post-apocalyptic world", type: "background", selected: false },
      { name: "an office", type: "background", selected: false },
    ],
    dialog: false,
  }),
  mounted() {
    if (this.keyword?.keyword) {
      this.imageContent = this.keyword?.keyword;
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    return { store, route };
  },
  computed: {
    article() {
      return this.store.state.article;
    },
    isLoadingImage() {
      return this.store.state.loading.loadingImage;
    },
    isLoadingSuggestions() {
      return this.store.state.article.loadingImagePromptSuggestions;
    },
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    isPreviewPage() {
      return this.route.path.includes("/preview");
    },
    getPrompt() {
      let prompt = `Image of ${this.imageContent}`;
      if (this.allStyles.filter((stl) => stl.selected).length > 0) {
        return `${prompt}, keywords: ${this.allStyles
          .filter((stl) => stl.selected)
          .map((stl) => stl.name)
          .join(", ")}`;
      }
      if (prompt[prompt.length - 1] == ",") {
        prompt = prompt.slice(0, prompt.length - 1);
      }
      return prompt;
    },
    styleTypes() {
      return Array.from(new Set(Object.values(this.allStyles).map((stl) => stl.type)));
    },
    imagePromptSuggestions() {
      return this.store.state.article.imagePromptSuggestions;
    },
  },
  methods: {
    addCustomChip(e: any) {
      this.allStyles.push({
        name: e.target.value,
        type: "A Custom",
        selected: true,
      });
      this.customStyle = "";
    },
    removeChip(chip: any) {
      this.allStyles = this.allStyles.filter((stl) => stl !== chip);
    },
    toggleImagePromptEdit() {
      this.imagePromptEditOpen = !this.imagePromptEditOpen;
    },
    imagePromptSelect(value: string) {
      if (value) {
        this.imageContent = value;
        // if (this.imagePromptSuggestions && !this.imagePromptSuggestions?.includes(value)) {
        //     this.imagePromptSuggestions = [...this.imagePromptSuggestions, value]
        // }
      }
    },
    scrollTo(section: string) {
      VueScrollTo.scrollTo(section);
    },
    approveImages(approved: boolean) {
      this.store.dispatch("article/approveArticleImages", approved);
      this.store.dispatch("article/generateSubtitlesForTitle", {
        title: this.article.title,
      });
      this.scrollTo("#subtitle-section");
    },
    // openImageDeletionModal(image: Image) {
    //     this.modalOpen = [image]
    // },
    removeImage(image: Image) {
      this.store.dispatch("article/removeImage", image);
    },
    // closeModal() {
    //     this.modalOpen = []
    // },
    // isModalOpen(image: Image) {
    //     return this.modalOpen[0] === image
    // },
    generateImage() {
      if (this.keyword?.keyword) {
        this.imagePromptEditOpen = false;
        this.store.dispatch("article/generateImage", {
          keyword: this.imagePrompt || this.getPrompt,
        });
      } else {
        this.store.dispatch("setErrorText", "Can't generate image. Keyword not found.");
      }
    },
    handleChipClick(e: any, style: object) {
      const skipAction = !!e.srcElement.className?.includes("mdi:mdi-close-circle");
      if (!skipAction) {
        this.allStyles = this.allStyles.map((stl) =>
          stl === style ? { ...stl, selected: !stl.selected } : stl
        );
      }
    },
    refreshImagePromptSuggestions() {
      //   if (this.article.title) {
      //     this.store.dispatch("refreshImagePromptSuggestions", this.article.title);
      //   }
    },
  },
  components: { WaitAnimation, CustomTitleSelector, ImageSelectorImage },
});
</script>
