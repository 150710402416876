import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bce0f94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["v-bind", "onClick"]
const _hoisted_3 = { class: "inline" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.variant !== 'select')
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "content-type-selector"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["content-card inline", category === _ctx.selectedContentType ? `selected ${_ctx.getPageCategory(category as string)}` : `${_ctx.getPageCategory(category as string)}`]),
                    "v-bind": category,
                    key: (category as string),
                    onClick: () => _ctx.setSelectedType(category as string)
                  }, [
                    (category === _ctx.selectedContentType)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(`outline inline ${_ctx.getPageCategory(category as string)}`)
                        }, null, 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(category), 1),
                    _createElementVNode("img", {
                      class: "type_img inline",
                      src: `${_ctx.publicPath}images/${_ctx.getPageCategory(category as string)}.png`
                    }, null, 8, _hoisted_4)
                  ], 10, _hoisted_2))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.variant === 'select')
          ? (_openBlock(), _createBlock(_component_v_select, {
              key: 1,
              disabled: !!_ctx.disable,
              variant: "outlined",
              items: _ctx.categories,
              modelValue: _ctx.selectedContentType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedContentType) = $event))
            }, null, 8, ["disabled", "items", "modelValue"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}