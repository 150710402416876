import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49e609d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "notification shadow" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "1" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "inline",
                src: `${_ctx.publicPath}images/info-circle.png`
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "18" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}