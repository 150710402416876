<template>
  <v-row id="subtitle-section" class="nomargin">
    <v-col cols="2">
      <p class="plain-title">SUBTITLES</p>
    </v-col>
    <v-col cols="10">
      <v-row class="nomargin">
        <v-col cols="12">
          <Transition>
            <div v-if="article.subtitles && !isLoadingSubtitles">
              <draggable class="dragArea list-group w-full" v-model="subtitles">
                <div class="structure-title" :key="subtitle" v-for="(subtitle, index) in article.subtitles">
                  <v-row>
                    <v-col cols="1">
                      <img class="inline pointer" :src="`${publicPath}images/sandwich.png`" />
                    </v-col>

                    <v-col cols="9" v-if="article.subtitles">
                      <h4 v-if="subtitle == 'cta'" class="inline cta">Call to action:</h4>

                      <Transition>
                        <h4 v-if="!subtitlesEdited.includes(subtitle)" class="inline">
                          {{ index + 1 }}. {{ subtitle }}
                        </h4>
                      </Transition>

                      <h4>
                        <textarea
                          v-on:change="(e: any) => handleSubtitleEdit(e.target.value, subtitle)"
                          type="textarea"
                          class="inline subtitle-edit"
                          variant="outlined"
                          v-if="subtitlesEdited.includes(subtitle)"
                          :value="subtitle"
                        ></textarea>
                      </h4>
                    </v-col>

                    <v-col cols="2">
                      <v-icon
                        v-if="!subtitlesEdited.includes(subtitle)"
                        class="checkmark pointer"
                        @click="editSubtitle(subtitle)"
                        >far fa-edit</v-icon
                      >
                      <v-icon
                        v-if="subtitlesEdited.includes(subtitle)"
                        class="checkmark pointer"
                        @click="editSubtitle(subtitle)"
                        role="img"
                        aria-hidden="false"
                      >
                        far fa-check-circle
                      </v-icon>
                      <v-icon
                        v-if="!subtitlesEdited.includes(subtitle)"
                        class="checkmark pointer"
                        @click="deleteSubtitle(subtitle)"
                        style="margin-left: 12px"
                        role="img"
                        aria-hidden="false"
                      >
                        far fa-trash
                      </v-icon>
                    </v-col>
                  </v-row>
                </div>
              </draggable>
            </div>
          </Transition>
        </v-col>
      </v-row>

      <v-row>
        <wait-animation
          v-if="isLoadingSubtitles"
          class="center"
          singleColor
          message="We are getting the article structure ready"
          :height="60"
          :width="60"
        />
        <v-col cols="12" class="float-right">
          <v-btn
            v-if="article.subtitles && article.title && !isLoadingSubtitles"
            @click="addSubtitle"
            class="plain-btn inline"
            ><span>+ ADD SUBTITLE</span>
          </v-btn>
          <v-btn
            @click="refreshSubtitles"
            v-if="(article.subtitles && !isLoadingSubtitles) || isPreviewPage"
            class="plain-btn inline"
            ><span>
              <span>{{ isPreviewPage ? "GENERATE" : "TRY AGAIN" }}</span>
              <v-icon>mdi:mdi-refresh</v-icon>
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<style scoped>
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.checkmark {
  color: #b500ff;
  margin-bottom: 10px;
}

.subtitle-edit {
  width: 100% !important;
  font-size: 16px;
  display: inline;
  border: rgba(0, 0, 0, 0.25) 0.1em solid;
  padding: 10px;
}

.subtitle-edit:focus {
  outline: none !important;
  border: rgba(0, 0, 0, 1) 0.15em solid;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  data: () => ({
    subtitlesEdited: [] as string[],
    publicPath: process.env.BASE_URL,
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  methods: {
    handleSubtitleEdit(newSubtitle: string, oldSubtitle: string) {
      const articleSubtitles = this.store.state.article.subtitles || [];
      const newArticleSubtitles = [];
      for (const st of articleSubtitles) {
        if (st === oldSubtitle) {
          newArticleSubtitles.push(newSubtitle);
        } else {
          newArticleSubtitles.push(st);
        }
      }
      this.store.dispatch("article/setArticleSubtitles", newArticleSubtitles);
    },
    editSubtitle(val: string) {
      if (this.subtitlesEdited.includes(val)) {
        this.subtitlesEdited = this.subtitlesEdited.filter((subtitle) => subtitle != val);
      } else {
        // this.subtitlesInEdit[val] = val
        this.subtitlesEdited.push(val);
      }
    },
    deleteSubtitle(subtitle: string) {
      this.store.dispatch("article/deleteSubtitle", { subtitle: subtitle });
    },
    addSubtitle() {
      this.store.dispatch("article/addSubtitle");
      this.subtitlesEdited.push("");
    },
    refreshSubtitles() {
      this.store.dispatch("article/generateSubtitlesForTitle", { title: this.article.title });
    },
  },
  components: { draggable: VueDraggableNext, WaitAnimation },
  computed: {
    article() {
      return this.store.state.article;
    },
    isLoadingSubtitles() {
      return this.store.state.loading.loadingSubtitles;
    },
    isPreviewPage() {
      return this.route.path.includes("/preview");
    },
    subtitles: {
      get() {
        return this.store.state.article.subtitles;
      },
      set(subtitles: string[]) {
        this.store.dispatch("article/reorderSubtitles", subtitles);
      },
    },
  },
});
</script>
