<template>
  <div ref="labelFilterContainer" :class="`filters ${expanded ? 'filters-expanded' : ''}`">
    <ul>
      <li class="inline filter">
        <div class="label-filters">
          <div class="label-filter-title"></div>
          <div
            :class="`label-filters ${selectedLevel === parseInt(level) ? 'selected-level' : ''}`"
            :key="level"
            v-for="[level, labels] in includeFilterLabelsByLevel"
          >
            <span class="s strong level-selector-title">Level {{ level }}</span>
            <div style="width: 100px; display: inline-block; margin-left: 12px">
              <MaireButton
                compact
                inverse
                :text="`${allLabelsSelectedInLevel(parseInt(level)) ? 'Unselect all' : 'Select all'}`"
                :onClick="() => selectAllLabels(parseInt(level))"
              />
            </div>
            <div style="display: block; margin-top: 12px">
              <span :key="label.text" v-for="label in labels">
                <MaireChip
                  :selected="label.checked"
                  :label="label.text"
                  :handleClick="() => handleClick(label)"
                  :closable="false"
                />
              </span>
            </div>
            <span
              class="level-view-selector"
              :class="`${selectedLevel === parseInt(level) ? 'icon-active' : ''}`"
            >
              <v-icon
                @click="changeLevel(parseInt(level))"
                @mouseenter="hoveringOn = level"
                @mouseleave="hoveringOn = ''"
                >fa-solid {{ getIconForLevel(level) }}</v-icon
              >
              <v-icon
                v-if="overrideRecommendedLevel && selectedLevel === parseInt(level)"
                @click="cancelOverride"
                >fa-solid fa-turn-down-left</v-icon
              >
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="expand-action" @click="toggleLabelDrawer" v-if="showMore">
    <v-icon>{{ expanded ? "fa-thin fa-circle-chevron-up" : "fa-thin fa-circle-chevron-down" }}</v-icon>
    Show {{ expanded ? "less" : "more" }}
  </div>
</template>
<style scoped>
.filter {
  width: 100%;
}
.selected-level {
  z-index: 1 !important;
  width: 100%;
}
.selected-level::after {
  padding: 20px;
  background-color: rgba(var(--v-theme-mairePurple), 0.05);
  /* width: 100%; */
  position: absolute;
  left: -12px;
  right: -12px;
  top: -4px;
  bottom: -4px;
  content: "";
  z-index: 0 !important;
  pointer-events: none;
}

.level-view-selector {
  position: absolute;
  font-size: 10px;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.level-view-selector i {
  margin-right: 12px;
}
.icon-active {
  color: rgba(var(--v-theme-mairePurple), 1);
}

.filters {
  position: relative;
  max-height: 800px;
  transition: max-height 0.3s ease-in-out; /* Add transition to max-height changes */
}
.filters:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}
.filters-expanded {
  max-height: v-bind(filterHeight);
}
.expand-action {
  text-align: center;
  cursor: pointer;
  margin-top: 12px;
  color: rgba(var(--v-theme-mairePurple), 1);
}
ul {
  padding: 0px 0px 0px;
}
.switch-btn {
  cursor: pointer;
  display: inline-block;
}
.label-filters {
  max-width: 100%;
  position: relative;
  padding: 8px 0px 24px;
}
.label-filter-title {
  margin-bottom: 4px;
}
.label-filters li {
  max-width: 100%;
}
li {
  display: inline-block;
  margin-bottom: 12px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { store } from "@/store";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireChip from "@/components/MaireChip/MaireChip.vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import { CheckboxState } from "@/store/modules/analysis/types";
import { useStore } from "@/store"

export default defineComponent({
  name: "LabelFilters",
  props: {
    selectedLevel: {
      type: Number,
      required: true,
      default: 1,
    },
    overrideRecommendedLevel: {
      type: Boolean,
      required: true,
    },
    changeLevel: {
      type: Function,
      required: true,
    },
    cancelOverride: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
    overflowing: false,
    hoveringOn: "",
    labelFilterContainer: ref(null),
    filterHeight: "3000px",
    elHeight: 0,
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    showMore() {
      return this.elHeight > 800;
    },
    searchWord() {
      return this.store.state.analysis.currentFilters.searchWord;
    },
    includedFilter() {
      return this.store.state.analysis.currentFilters.labelFilters.include;
    },
    includeFilterLabelsByLevel() {
      const res = this.includedFilter.labels.reduce(
        (acc: Record<number, CheckboxState[]>, label: CheckboxState) => {
          if (label.level) {
            if (!acc[label.level]) {
              acc[label.level] = [];
            }
            acc[label.level].push(label);
          }
          return acc;
        },
        {}
      );
      return Object.entries(res);
    },
  },
  watch: {
    expanded() {
      const el = this.$refs.labelFilterContainer as HTMLElement;
      if (el) {
        this.filterHeight = `${el.scrollHeight}px`;
      }
    },
  },
  methods: {
    getIconForLevel(level: string) {
      if(this.overrideRecommendedLevel && this.selectedLevel === parseInt(level)) {
        return "fa-thumbtack"
      }
      return this.hoveringOn === level
        ? "fa-thumbtack"
        : this.selectedLevel === parseInt(level)
        ? "fa-eye"
        : "fa-eye-slash";
    },
    allLabelsSelectedInLevel(level: number) {
      return this.store.state.analysis.currentFilters.labelFilters.include.labels
        .filter((label) => label.level === level)
        .every((label: CheckboxState) => label.checked);
    },
    toggleLabelDrawer() {
      this.expanded = !this.expanded;
    },
    selectAllLabels(level: number) {
      if (this.allLabelsSelectedInLevel(level)) {
        store.dispatch("analysis/deselectAllLabels", level);
      } else {
        store.dispatch("analysis/selectAllLabels", level);
      }
    },
    numberFormatter,
    handleClick(cb: CheckboxState) {
      store.dispatch("analysis/toggleLabelFilter", { cb });
    },
  },
  components: {
    MaireChip,
    MaireButton,
  },
});
</script>
