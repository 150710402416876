<template>
  <v-container
    class="paper"
    id="split-view-container"
    @mouseleave="handlePreviewHover"
    @mouseover="gutters = true"
  >
    <div class="section title-section">
      <v-textarea
        @mouseover="titleEditOpen = true"
        :rows="1"
        class="preview-title"
        variant="plain"
        id="title-ghost-h2"
        auto-grow
        v-model="title"
        autofocus
      />
      <Transition name="slide">
        <TitleSelector
          v-if="titleEditOpen"
          @mouseleave="handleTitleHover"
          :already-opened="true"
          :sidebar="false"
          :allow-custom="false"
          :skip-next-steps="!!article.abstract"
        />
      </Transition>
    </div>
    <Transition name="gutter_slide">
      <div v-if="gutters" class="gutter"></div>
    </Transition>
    <div class="section abstract" v-if="article.title">
      <v-textarea
        autofocus
        v-if="!isLoadingAbstract"
        class="preview-abstract"
        rows="3"
        density="compact"
        variant="plain"
        auto-grow
        v-model="article.abstract"
      />
      <div v-if="isLoadingAbstract" class="wait-animation">
        <WaitAnimation
          :height="60"
          :width="60"
          v-if="isLoadingAbstract"
          message="Maire is writing, please wait."
        />
      </div>
      <v-btn
        v-if="gutters && !isLoadingAbstract"
        @click="() => continueWriting('abstract', article.abstract)"
        class="plain-btn inline"
      >
        <div v-if="!article.loadingSection?.includes('abstract')">
          <v-icon style="padding-right: 10px">fa-solid fa-plus</v-icon><span>CONTINUE WRITING</span>
        </div>
        <v-progress-circular
          v-if="article.loadingSection?.includes('abstract')"
          size="20"
          indeterminate
          color="#8200FF"
        ></v-progress-circular>
      </v-btn>
      <v-btn v-if="gutters && !isLoadingAbstract" @click="regenerateArticle" class="plain-btn inline">
        <v-icon style="padding-right: 10px">fa-solid fa-rotate</v-icon
        ><span class="inline">REGENERATE THIS PARAGRAPH</span>
      </v-btn>
    </div>
    <Transition name="gutter_slide">
      <div v-if="article.title && gutters" class="gutter">
        <v-btn
          v-if="!openTitleSections.includes('first')"
          @click="() => toggleSectionEditor('first')"
          class="btn2 plain-btn inline"
        >
          <span>+ ADD SECTION</span>
        </v-btn>
        <div :class="`section-add ${openTitleSections.includes('first') ? 'gray-background' : ''}`">
          <CustomTitleSelector
            :loading="isLoadingSubtitleSuggestions"
            :on-select="(title: string) => handleNewSubtitle(title, 'first')"
            :suggestions="subtitleSuggestions"
            :selected="'lol'"
            v-if="openTitleSections.includes('first')"
            :already-opened="true"
            :sidebar="false"
            :refresh="refreshTitleSuggestions"
          />
        </div>
      </div>
    </Transition>
    <div class="structure section" v-if="article.title">
      <div :id="section.title" class="section-item" :key="section.title" v-for="section in article.sections">
        <!-- <h3>{{ section.title }}</h3> -->
        <div class="cursor">
          <textarea
            autofocus
            rows="1"
            v-on:change="(e: any) => section.title = e.target.value"
            multi-line
            id="title-ghost-h3"
            density="compact"
            variant="plain"
            auto-grow
            :value="section.title"
          >
          </textarea>
        </div>
        <WaitAnimation
          :height="60"
          :width="60"
          v-if="section.loading"
          message="Maire is writing, please wait."
        />
        <v-textarea
          autofocus
          v-if="!section.loading"
          rows="3"
          density="compact"
          variant="plain"
          auto-grow
          v-model="section.text"
        />
        <br />
        <v-btn
          v-if="gutters && !isLoadingAbstract && section.text"
          @click="() => continueWriting(section.title, section.text)"
          class="plain-btn inline"
        >
          <div v-if="!article.loadingSection?.includes(section.title)">
            <v-icon style="padding-right: 10px">mdi:mdi-plus</v-icon><span>CONTINUE WRITING</span>
          </div>
          <v-progress-circular
            v-if="article.loadingSection?.includes(section.title)"
            size="20"
            indeterminate
            color="#8200FF"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          v-if="gutters && !section.loading"
          @click="generateParagraph(section.title)"
          class="plain-btn inline"
        >
          <v-icon v-if="section.text">mdi:mdi-refresh</v-icon>
          <span v-if="!section.text"><v-icon>mdi:mdi-pencil</v-icon> write paragraph</span>
        </v-btn>
        <v-icon
          v-if="gutters"
          class="icon inline btn-danger"
          @click="toggleModal(section)"
          :style="`float: right;`"
        >
          mdi:mdi-close
        </v-icon>
        <Transition name="gutter_slide">
          <div v-if="gutters" class="gutter">
            <v-btn
              v-if="!openTitleSections.includes(section.title)"
              @click="() => toggleSectionEditor(section.title)"
              class="btn2 plain-btn inline"
            >
              <span>+ ADD SECTION</span>
            </v-btn>
            <div :class="`section-add ${openTitleSections.includes(section.title) ? 'gray-background' : ''}`">
              <CustomTitleSelector
                :loading="isLoadingSubtitleSuggestions"
                :on-select="(title: string) => handleNewSubtitle(title, section.title)"
                :suggestions="subtitleSuggestions"
                :selected="'lol'"
                v-if="openTitleSections.includes(section.title)"
                :already-opened="true"
                :sidebar="false"
                :refresh="refreshTitleSuggestions"
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <MaireDialog
      title="Are you sure?"
      :description="`Deleting: ${sectionToDelete?.title}.`"
      :open="sectionModalopen"
      :onClose="
        () => {
          sectionModalopen = false;
        }
      "
      :fn="removeSection"
    >
    </MaireDialog>
  </v-container>
</template>
<style scoped>
.title-section {
  padding-bottom: 20px !important;
}

.wait-animation {
  height: 100px;
}

.wait-animation-writing {
  height: 20px;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 24px;
  opacity: 0;
}

.gutter_slide-leave-active,
.gutter_slide-enter-active {
  transition: all 0.5s ease-in-out;
}

.gutter_slide-enter-to,
.gutter_slide-leave-from {
  max-height: 500px;
  padding: 12px 0px !important;
}

.gutter_slide-enter-from,
.gutter_slide-leave-to {
  max-height: 0px !important;
  padding: 0px 0px !important;
  opacity: 0;
}

.section-item {
  position: relative;
}

.btn-danger {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #ff002e !important;
}

.section {
  background-color: rgba(130, 0, 255, 0.03);
  padding: 0 24px;
  margin-left: -24px;
  margin-right: -24px;
}

.section-add {
  padding: 0 24px;
  min-height: 40px;
  /* margin-top: 50px;
    margin-bottom: 50px; */
}

.gray-background {
  background-color: rgba(41, 54, 87, 0.03);
  margin-top: 20px;
  margin-bottom: 20px;
}

.gutter {
  /* min-height: 0px; */
  /* height: 100%; */
  background-color: #f7f7f7;
  margin-left: -24px;
  margin-right: -24px;
  padding: 12px 0px;
  position: relative;
}

.paper {
  white-space: pre-wrap;
}

.preview-abstract,
.preview-images {
  padding-bottom: 30px;
  padding-top: 10px;
}

input {
  padding: 100px !important;
}

/* .plain-btn {
    margin-left: 0px !important;
    margin-top: -50px !important;
    margin-right: 12px;
} */

.gutter .btn2 {
  margin: 0 !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  padding-left: 0px;
}

h3 {
  font-size: 20px;
}

.paper {
  padding: 40px;
  min-height: 100%;
}

.paper img {
  width: 100%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import TitleSelector from "../TitleSelector/TitleSelector.vue";
import CustomTitleSelector from "../TitleSelector/CustomTitleSelector.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { Subtitle } from "@/store/modules/article/types";
import { useStore } from "@/store";
import { useRouter } from "vue-router"

export default defineComponent({
  data: () => ({
    titleEditOpen: false,
    gutters: false,
    openTitleSections: [] as string[],
    sectionModalopen: false,
    sectionToDelete: undefined as Subtitle | undefined,
  }),
  setup() {
    const store = useStore();
    const router = useRouter();
    return { store, router };
  },
  mounted() {
    if (this.store.state.article.title) {
      this.store.dispatch("article/generateSubTitleSuggestions", { title: this.article.title });
    }
  },
  computed: {
    article() {
      return this.store.state.article;
    },
    isLoadingAbstract() {
      return this.store.state.loading.loadingAbstract;
    },
    isLoadingSubtitleSuggestions() {
      return this.store.state.loading.loadingSubtitles;
    },
    title: {
      get() {
        return this.store.state.article.title || "Hover to select a title";
      },
      set(title: string) {
        this.store.dispatch("article/selectTitle", title);
      },
    },
    subtitleSuggestions() {
      const existing = this.store.state.article.sections?.map((sect) => sect.title);
      return this.store.state.article.subTitleSuggestions?.filter(
        (sugg) => !existing?.includes(sugg as string)
      );
    },
  },
  methods: {
    continueWriting(sectionName: string, text?: string) {
      this.store.dispatch("article/continueWriting", {
        title: this.article.title,
        sectionName: sectionName,
        text: text,
      });
    },
    regenerateArticle() {
      this.store.dispatch("article/generateAbstractForTitle", { title: this.article.title });
    },
    generateParagraph(subtitle: string) {
      this.store.dispatch("article/generateParagraphForSubtitle", {
        title: this.article.title,
        subtitle: subtitle,
      });
    },
    triggerTitleModal(open: boolean) {
      this.titleEditOpen = open;
      this.gutters = true;
    },
    handleTitleHover(e: MouseEvent | any) {
      const relatedTarget = e?.relatedTarget;
      if (
        !(relatedTarget?.parentElement?.id?.includes("titleselector") || relatedTarget?.id?.includes("title"))
      ) {
        this.titleEditOpen = false;
      }
    },
    handlePreviewHover(e: MouseEvent | any) {
      const relatedTarget = e?.relatedTarget;
      if (
        !(relatedTarget?.parentElement?.id?.includes("titleselector") || relatedTarget?.id?.includes("title"))
      ) {
        this.titleEditOpen = false;
      }
      this.gutters = false;
    },
    handleNewSubtitle(newSubtitle: string, section: string) {
      this.toggleSectionEditor(section);

      this.store.dispatch("article/addNewSection", { section, newSubtitle });
    },
    toggleSectionEditor(section: string) {
      if (this.openTitleSections.includes(section)) {
        this.openTitleSections = this.openTitleSections.filter((openedSection) => openedSection !== section);
      } else {
        this.openTitleSections.push(section);
      }
    },
    refreshTitleSuggestions() {
      this.store.dispatch("article/generateSubTitleSuggestions", { title: this.article.title });
    },
    toggleModal(section: Subtitle) {
      this.sectionModalopen = !this.sectionModalopen;
      this.sectionToDelete = section;
    },
    removeSection() {
      this.store.dispatch("article/removeSection", { section: this.sectionToDelete });
      this.sectionModalopen = false;
    },
  },
  componens: { WaitAnimation },
  components: { WaitAnimation, TitleSelector, CustomTitleSelector, MaireDialog },
});
</script>
