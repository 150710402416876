<template>
  <div class="main">
    <div class="content">
      <div v-if="page === 1" class="first_page">
        <h2 id="title">Give Maire some more information</h2>
        <h3>Keyword</h3>
        <p>
          Maire needs a keyword to work with. This should be the thing you want to write about. Don’t worry
          about coming up with anything too elaborate. Maire will analyse what’s out there and give you some
          topic suggestions.
        </p>
        <div class="keyword_selector">
          <v-text-field
            v-model="keyword"
            :items="favoriteKeywords"
            item-title="keyword"
            item-value="id"
            label="A keyword you want to write about"
            ref="model_to_update"
            variant="outlined"
            class="mb-4"
            density="compact"
            :error="!!keywordError"
            :error-messages="keywordError"
          ></v-text-field>
        </div>
        <h3>Language</h3>
        <p>Maire needs to know what language you want to write in.</p>
        <div class="keyword_selector">
          <v-select
            ref="location"
            :items="languages"
            variant="outlined"
            v-model="language"
            label="Location"
            cols="4"
            class="mb-4"
            density="compact"
            :error="!!locationError"
            :error-messages="locationError"
          />
        </div>
        <h3>Location</h3>
        <p>Maire needs to know where your customers are in order to provide better suggestions.</p>
        <div class="keyword_selector">
          <v-select
            ref="location"
            :items="countries"
            variant="outlined"
            v-model="location"
            label="Location"
            cols="4"
            class="mb-4"
            density="compact"
            :error="!!languageError"
            :error-messages="languageError"
          />
        </div>

        <div class="button-container">
          <v-btn class="plain-btn" @click="nextPage">Continue</v-btn>
        </div>
      </div>

      <div v-if="page === 2" class="second_page">
        <div>
          <WaitAnimation v-if="!keywordId" message="Maire is fetching basic details for the keyword." />
        </div>
        <Transition>
          <div v-if="keywordId">
            <h2 id="title">Choose a template you want to work with</h2>
            <p v-if="recommendedCategory && !loadingSerp" class="main_description">
              Maire recommends that you create a <strong>{{ recommendedCategory }}</strong> for this keyword
              in order to start ranking high. You can of course choose something else too.
            </p>
            <p v-if="!recommendedCategory && !loadingSerp" class="main_description">
              Please select a content type you'd want to create.
            </p>

            <div v-if="loadingSerp" class="suggestion_loader_container">
              <WaitAnimation :height="50" message="Maire is analyzing the keyword." />
            </div>
            <div class="container">
              <div
                @click="goToArticleCreation(category)"
                :class="`item ${recommendedCategory == category.contentType ? 'selected' : ''} `"
                :key="category.contentType"
                v-for="category in categories()"
              >
                <span class="contentTitle">{{ category.contentType }}</span>
                <img
                  class="type_img inline"
                  :src="`${publicPath}images/${category.category.replace('/', '')}.png`"
                />
                <p>{{ category.shortDescription }}</p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active {
  transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#title {
  padding-bottom: 50px;
}

h3 {
  font-size: 20px;
  line-height: 20px;
  margin-top: 0px;
  padding-bottom: 12px;
}

.main {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  height: 100%;
}

.button-container {
  padding-top: 12px;
}

.keyword_selector {
  padding-top: 24px;
}

.content {
  text-align: center;
  margin-left: 25%;
  margin-right: 25%;
}

.content p {
  text-align: left;
}

.second_page p {
  text-align: center;
  padding-bottom: 24px;
}

.plain-btn {
  border-radius: 15px;
}

.second_page .container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: -10%;
  margin-right: -10%;
  /* Optional: Add space between items */
}

.second_page .item {
  flex: 0;
  /* This property makes each item flexible, taking up the remaining space in the container */
  min-width: 190px !important;
  min-height: 200px;
  /* Set a minimum width for each item */
  background-color: #f1f1f1;
  padding: 14px;
  text-align: left;
  box-sizing: border-box;
  color: rgba(var(--v-theme-maireDarkBlue));
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  /* 2px border with color var(--v-theme-mairePurple), border radius 10px */
}

h2 {
  padding-bottom: 24px !important;
}

.main_description {
  margin-left: 20%;
  margin-right: 20%;
  font-size: 16px;
  padding-bottom: 48px !important;
}

.second_page .item p {
  text-align: left;
  padding-top: 10px;
}

.second_page .item.selected {
  /* 2px border with color var(--v-theme-mairePurple), border radius 10px */
  outline: 2px solid rgba(var(--v-theme-mairePurple));
  border-radius: 10px;
  cursor: pointer;
}

.second_page .item:hover {
  /* 2px border with color var(--v-theme-mairePurple), border radius 10px */
  outline: 2px solid rgba(var(--v-theme-mairePurple));
  border-radius: 10px;
  cursor: pointer;
}

.contentTitle {
  font-weight: 600;
}

.second_page .item:hover .contentTitle {
  color: rgba(var(--v-theme-mairePurple));
}

.second_page .item:hover .type_img {
  color: rgba(var(--v-theme-mairePurple));
  filter: grayscale(0%);
}

.type_img {
  float: right;
  height: 30px;
  filter: grayscale(100%);
}
.suggestion_loader_container {
  /**center everything horizontally in the div */
  display: flex;
  justify-content: center;
  /**center everything vertically in the div */
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-top: -20px;
}
</style>

<script lang="ts">
import {
  CONTENT_CATEGORIES,
  Category,
  recommendedCategories,
} from "@/components/ArticleGeneration/categories";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from "@/components/helpers/geo_constants";
import { Keyword } from "@/store/modules/keywords/types";
import { defineComponent } from "vue";
import { useStore } from "@/store"
import { useRoute, useRouter } from "vue-router"

const KEYWORD_ERROR = "Please enter a keyword";
const LOCATION_ERROR = "Please enter a location";
const LANGUAGE_ERROR = "Please enter a language";

export default defineComponent({
  name: "ContentCreationView",
  data: () => ({
    keyword: "",
    location: "",
    language: "",
    keywordError: "",
    locationError: "",
    languageError: "",
    page: 1,
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    if (this.userSearch.location) {
      this.location = this.userSearch.location;
    }
    if (this.userSearch.language) {
      this.language = this.userSearch.language;
    }
    this.store.dispatch("keywords/clearKeywordWithDetails");
  },
  watch: {
    keyword() {
      this.validateKeyword();
      this.keyword = this.keyword.toLowerCase();
    },
    location() {
      this.validateLocation();
    },
    language() {
      this.validateLanguage();
    },
  },
  computed: {
    favoriteKeywords() {
      return this.store.state.keywords.analysisKeywords.map((keyword: Keyword) => {
        return {
          keyword: `${keyword.keyword} | volume: ${keyword.volume} | ${keyword.localization.location_name} (${keyword.localization.language_code})`,
          id: keyword.id,
        };
      });
    },
    countries() {
      return SUPPORTED_COUNTRIES;
    },
    languages() {
      return SUPPORTED_LANGUAGES;
    },
    publicPath() {
      return process.env.BASE_URL;
    },
    keywordId() {
      return this.store.state.keywords.keywordWithDetails?.id;
    },
    keywordWithDetails() {
      return this.store.state.keywords.keywordWithDetails;
    },
    company() {
      return this.store.state.company;
    },
    userSearch() {
      return this.store.state.userSearch;
    },
    loadingSerp() {
      return this.store.state.loading.loadingSerp;
    },
    recommendedCategory() {
      const suggestedCategories = recommendedCategories();
      if (suggestedCategories.length > 0) {
        return suggestedCategories[0].contentType;
      } else {
        return null;
      }
    },
  },
  methods: {
    startFromScratch() {
      this.router.push("/content/new");
    },
    validateKeyword() {
      if (this.keyword === "") {
        this.keywordError = KEYWORD_ERROR;
      } else {
        this.keywordError = "";
      }
    },
    validateLocation() {
      if (this.location === "") {
        this.locationError = LOCATION_ERROR;
      } else {
        this.locationError = "";
      }
    },
    validateLanguage() {
      if (this.language === "") {
        this.languageError = LANGUAGE_ERROR;
      } else {
        this.languageError = "";
      }
    },
    validateInput() {
      this.validateKeyword();
      this.validateLocation();
      this.validateLanguage();
    },
    categories() {
      return CONTENT_CATEGORIES;
    },
    nextPage() {
      this.validateInput();
      if (this.keyword === "" || this.location === "" || this.language === "") {
        return;
      } else {
        this.store.dispatch("keywords/getOrCreateKeyword", {
          keyword: this.keyword,
          location: this.location,
          language: this.language,
        });
        this.page += 1;
      }
    },
    goToArticleCreation(category: Category) {
      if (!this.keywordId) {
        this.store.dispatch("sendErrorMessage", "Please select a keyword first");
      } else {
        this.router.push({
          name: "articleWithType",
          params: {
            id: this.keywordId,
            contentType: category.contentType,
          },
        });
      }
    },
  },
  components: { WaitAnimation },
});
</script>
