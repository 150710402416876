<template>
  <v-card class="notification shadow">
    <v-row cols="12">
      <v-col cols="1"><img class="inline" :src="`${publicPath}images/info-circle.png`" /></v-col>
      <v-col cols="18">
        <p class="inline">{{ message }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.notification {
  border-radius: 0px;
  padding: 30px;
  margin-right: 50px;
  margin-left: -12px;
}

.shadow {
  box-shadow: rgba(41, 54, 87, 0.1) 0px 0px 20px 0px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  props: {
    message: String,
  },
  computed: {},
  methods: {},
});
</script>
