<template>
  <div id="action-bar">
    <div class="view-name">
      <p class="view-title s strong">
        {{ viewName }}
        <span class="chip google-only" v-if="analysis?.volume_field === 'monthly_google_searches'">
          Google only
        </span>
      </p>
    </div>
    <div class="action-container" v-show="!loading">
      <ActionIcon
        icon="fa-regular fa-gear"
        :onToggle="() => toggleSection('configure')"
        :toggled="toggledSection === 'configure'"
      />
      <ActionIcon
        icon="fa-solid fa-bars"
        :onToggle="() => toggleSection('manage')"
        :toggled="toggledSection === 'manage'"
        v-if="userIsNotViewer"
      />
      <div class="content paper-background" v-if="toggledSection">
        <div class="content-title s strong">
          {{ title }}
        </div>
        <div v-if="toggledSection === 'configure'">
          <v-switch
            v-model="editMode"
            label="Edit mode"
            color="purple"
            class="edit-mode-switch"
            hide-details
            v-if="view?.edit_access"
          />
          <div v-if="editMode">
            <v-switch
              v-model="showAllLabels"
              label="Search from all labels"
              color="purple"
              class="edit-mode-switch"
              hide-details
            />
          </div>
          <div v-if="editMode" @mouseover="handleMouseEnter" @mouseleave="handleMouseLeave">
            <div v-if="selected">
              <TransitionGroup name="fade" tag="div" class="level-list">
                <div
                  :class="`level-selector inputs drop-zone ${hoveringOn === level ? 'drop-highlight' : ''}`"
                  :key="level"
                  v-for="level in Object.keys(selected)"
                  @dragenter.prevent="() => onDragEnter(level)"
                  @dragleave.prevent
                  @dragover.prevent
                  @drop.prevent="onDrop($event, level)"
                >
                  <div class="drag-el" :draggable="true" @dragstart="startDrag($event, level)">
                    <Transition name="slide">
                      <div class="add-level-button" v-if="structureValid && hovering">
                        <v-icon class="preventClick centeredIcon plusIcon" @click="addLevel(level)">
                          fa-regular fa-plus
                        </v-icon>
                        <span class="s">Add level</span>
                      </div>
                    </Transition>
                    <div class="s strong level-selector-title">
                      Choose level {{ level }}
                      <v-icon
                        v-if="maxLevel === parseInt(level) && selected[parseInt(level)]?.length === 0"
                        @click="removeLevel(level)"
                        color="#8200ff"
                        class="remove"
                        >fa-solid fa-trash</v-icon
                      >
                      <v-icon
                        v-if="selected[parseInt(level)]?.length > 0"
                        @click="clearModalOpen = level"
                        color="#8200ff"
                        class="remove"
                        >fa-solid fa-broom-wide</v-icon
                      >
                      <MaireDialog
                        :open="clearModalOpen === level"
                        :title="`Do you want to unselect all labels from level ${level}?`"
                        description="You need to manually add them back again in case you need them."
                        :fn="() => clearLevel(level)"
                        :onClose="() => (clearModalOpen = undefined)"
                      />
                    </div>
                    <v-autocomplete
                      variant="outlined"
                      v-model="selected[parseInt(level)]"
                      :items="showAllLabels ? labels : labelsInAnalysisKeywords"
                      item-title="text"
                      :item-value="(item) => item"
                      :hide-selected="true"
                      label="Add label"
                      density="compact"
                      :hide-details="true"
                      multiple
                    />
                    <div class="chips" :v-if="!(hoveringOn === level)">
                      <span :key="label.text" v-for="label in labelsForLevel(level)">
                        <MaireChip
                          :label="label.text"
                          :handleClose="() => handleLabelClose(label)"
                          :handleClick="() => {}"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </TransitionGroup>
              <div class="add-level-button" v-if="structureValid">
                <v-icon class="preventClick centeredIcon plusIcon" @click="() => addLevel()">
                  fa-regular fa-plus
                </v-icon>
                <span class="s">Add level</span>
              </div>
            </div>
            <div class="save-button">
              <MaireButton
                text="Save"
                class="save-analysis-button"
                @click="saveStructure"
                :disabled="structureUpToDate || !structureValid"
                color="primary"
                variant="contained"
                size="small"
              />
            </div>
          </div>
          <div v-else>
            <span class="s">Click the labels to filter</span>
            <LabelFilters
              :selectedLevel="selectedLevel"
              :changeLevel="changeLevel"
              :cancelOverride="cancelOverride"
              :overrideRecommendedLevel="overrideRecommendedLevel"
            />
          </div>
        </div>
        <div v-if="toggledSection === 'labels'"></div>
        <div v-if="toggledSection === 'manage'">
          <CreateAnalysisFilterModal
            v-if="saveModalOpen && analysis"
            :type="saveType"
            :details="newAnalysis ?? saveDetails"
            :open="saveModalOpen"
            :onSave="handleSave"
            :onClose="toggleModal"
            :kwIds="newAnalysis?.localized_keywords_ids"
          />
          <div
            :key="action.text"
            class="manage-action pointer"
            v-for="action in actions"
            @click="action.action"
          >
            <v-icon>{{ action.icon }}</v-icon
            ><span class="xs">{{ action.text }}</span>
          </div>
          <ShareItemModal
            v-if="shareData"
            :item="shareData.item"
            :item-type="shareData.type"
            :clear-item="clearItemToShare"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.level-selector {
  /* transition: transform 0.3s ease-in-out; */
  transition: all 0.3s ease-in-out;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0px !important;
  opacity: 0;
  margin-top: -10px !important;
}
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px 0px;
  height: 100%;
  outline: 0px solid rgba(var(--v-theme-mairePurpleSecondary), 0.5);
}
.drop-zone.drop-highlight {
  background-color: #d1ecf1;
  outline: 4px solid rgba(var(--v-theme-mairePurpleSecondary), 0.5) !important;
  filter: brightness(95%);
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 2px solid black;
}
.remove {
  cursor: pointer;
  float: right;
}
.view-title {
  color: white;
  margin: auto;
  width: 50%;
  position: absolute;
  padding: 10px 10px 10px 100px;
  display: inline-block;
}
.edit-mode-switch {
  position: relative;
}
#action-bar .manage-action {
  display: flex;
  align-items: center;
  padding: 12px 12px;
}

#action-bar .manage-action:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.1);
}

#action-bar .manage-action span {
  margin-left: 12px;
}
#action-bar {
  position: fixed;
  z-index: 1005;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
}

.add-level-button {
  color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  margin: 12px 0px;
  display: flex;
  align-items: center;
}

.add-level-button span {
  margin-left: 5px;
  font-size: 12px;
}

.plusIcon {
  outline: 0.5px solid rgba(var(--v-theme-mairePurpleSecondary), 1) !important;
  /* outline-offset: 0.3em !important; */
  font-size: 14px;
}

.action-container {
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
  padding: 0 12px;
  margin-right: 12px;
}

.content {
  position: absolute;
  right: 0px;
  top: 42px;
  align-items: center;
  height: calc(100vh - 42px);
  overflow-y: scroll;
  padding-bottom: 24px;
  width: 350px;
  background-color: white;
}

.content .content-title {
  padding: 12px 0;
}

.level-selector {
  padding: 0px 0;
}
.level-selector-title {
  padding-bottom: 12px;
  cursor: pointer;
}
.chips {
  padding: 12px 0 0 0;
  color: grey;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import ActionIcon from "@/components/ActionBar/ActionIcon.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChip from "@/components/MaireChip/MaireChip.vue";
import { AnalysisFilterViewLabels, ViewLabel } from "@/store/modules/labels/types";
import CreateAnalysisFilterModal from "../CreateAnalysisFilterModal/CreateAnalysisFilterModal.vue";
import ShareItemModal from "../ShareItemModal/ShareItemModal.vue";
import { viewLabelsToStructure } from "@/store/modules/labels/helpers";
import LabelFilters from "@/components/LabelFilters/LabelFilters.vue";
import { store } from "@/store";
import {
  Analysis,
  AnalysisCreate,
  AnalysisDetails,
  AnalysisUpdate,
  View,
} from "@/store/modules/analysis/types";
import MaireDialog from "../MaireDialog/MaireDialog.vue";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ActionBar",
  props: {
    viewId: {
      type: Number,
      required: true,
    },
    selectedLevel: {
      type: Number,
      required: true,
      default: 1,
    },
    overrideRecommendedLevel: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    changeLevel: {
      type: Function,
      required: true,
    },
    cancelOverride: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    return { route, store };
  },
  data: () => ({
    selected: {} as { [key: number]: any[] },
    hovering: false,
    hoveringOn: "" as string,
    structureMissing: false,
    saveType: "Analysis" as "Analysis" | "View",
    saveDetails: {} as AnalysisUpdate,
    newAnalysis: undefined as undefined | AnalysisCreate,
    showAllLabels: false,
    editMode: true,
    clearModalOpen: undefined as number | string | undefined,
    toggledSection: "configure",
    hierarchy: {} as AnalysisFilterViewLabels,
    contentTitles: {
      configure: "Configure analysis view",
      // labels: "Manage visible labels",
      manage: "",
    } as Record<string, string>,
    saveModalOpen: false,
    shareData: undefined as undefined | { item: Analysis | View; type: "analysis" | "view" },
  }),
  watch: {
    viewLabels: {
      immediate: true,
      handler() {
        this.resetUI();
      },
    },
    analysis: {
      immediate: true,
      handler() {
        if (this.analysis?.id) {
          store.dispatch("labels/fetchLabelsInAnalysis", this.analysis.id);
        }
      },
    },
  },
  mounted() {
    store.dispatch("rightNavbarOpen", true);
    if (!this.labels.length) {
      this.store.dispatch("labels/fetchLabels");
    }
  },
  computed: {
    title() {
      return this.contentTitles[this.toggledSection as any];
    },
    viewName() {
      return this.analysis?.name && this.view?.name ? `${this.analysis?.name}  - ${this.view?.name}` : "";
    },
    viewLabels() {
      return this.store.state.labels.viewLabels?.[this.viewId];
    },
    labels() {
      return Object.values(this.store.state.labels.labels).filter(
        (x) => !this.labelsInLevels.includes(x.text)
      );
    },
    labelsInAnalysisKeywords() {
      if (!this.analysis?.id) return;
      return (
        this.store.state.labels.analysisLabels?.[this.analysis?.id]?.filter(
          (label) => !this.labelsInLevels.includes(label.text)
        ) || []
      );
    },
    labelsInLevels() {
      return Object.values(this.selected)
        .flatMap((x: any) => x)
        .map((x: any) => x.text);
    },
    maxLevel() {
      return Math.max(...Object.keys(this.selected ?? {}).map(Number));
    },
    structureUpToDate() {
      return JSON.stringify(Object.values(this.selected).flat()) === JSON.stringify(this.viewLabels);
    },
    structureValid() {
      return Object.values(this.selected)
        .slice(0, -1)
        .every((x: any) => x.length);
    },
    analysis() {
      const afId = this.store.state.analysis.currentAnalysis?.id;
      return this.store.state.analysis.analyses?.find((x) => x.id === afId);
    },
    view() {
      return this.store.state.analysis.views?.[this.viewId];
    },
    actions() {
      const actions = [];
      if (this.analysis?.edit_access) {
        actions.push({
          text: "Save (Update) Analysis",
          action: () => {
            if (!this.analysis) return;
            const { id, name, description } = this.analysis;
            this.saveDetails = { id, name, description };
            this.saveType = "Analysis";
            this.toggleModal();
          },
          icon: "fa-regular fa-save",
        });
      }
      actions.push({
        text: "Save as new Analysis",
        action: () => {
          if (!this.analysis) return;
          this.newAnalysis = {
            name: this.analysis.name + " (Copy)",
            description: this.analysis.description,
            localized_keywords_ids: this.analysis?.keywords.map((kw) => kw.id),
          };
          this.saveType = "Analysis";
          this.toggleModal();
        },
        icon: "fa-regular fa-floppy-disk-circle-arrow-right",
      });
      if (this.userHasOwnerAccess(this.analysis)) {
        actions.push({
          text: "Share Analysis",
          action: () => {
            this.shareData = { item: this.analysis as Analysis, type: "analysis" };
          },
          icon: "fa-regular fa-share-nodes",
        });
      }
      if (this.view?.edit_access) {
        actions.push({
          text: "Save (Update) View",
          action: () => {
            this.saveDetails = this.view;
            this.saveType = "View";
            this.toggleModal();
          },
          icon: "fa-regular fa-save",
        });
      }

      actions.push({
        text: "Create a new View",
        action: () => {
          this.saveDetails = {
            ...this.view,
            id: undefined,
            name: this.view?.name + " (Copy)",
          };
          this.saveType = "View";
          this.toggleModal();
        },
        icon: "fa-regular fa-floppy-disk-circle-arrow-right",
      });
      if (this.userHasOwnerAccess(this.view)) {
        actions.push({
          text: "Share View",
          action: () => {
            this.shareData = { item: this.view, type: "view" };
          },
          icon: "fa-regular fa-share-nodes",
        });
      }
      return actions;
    },
    userIsNotViewer() {
      return this.store.state.user?.type !== "viewer";
    },
  },
  methods: {
    onDragEnter(level: string) {
      this.hoveringOn = level;
    },
    startDrag(evt: DragEvent, level: string) {
      if (evt.dataTransfer) {
        evt.dataTransfer.dropEffect = "move";
        evt.dataTransfer.effectAllowed = "move";
        evt.dataTransfer.setData("text/plain", level);
      }
    },
    onDrop(evt: DragEvent, newLevel: string) {
      const startLevel = evt?.dataTransfer?.getData("text/plain"); // works
      if (!newLevel || !startLevel) return;
      this.selected = {
        ...this.selected,
        [newLevel]: this.selected[parseInt(startLevel)],
        [startLevel]: this.selected[parseInt(newLevel)],
      };
      this.hoveringOn = "";
    },
    handleMouseLeave(e: any) {
      if (!e?.toElement?.className?.includes("v-list") && this.hovering) {
        this.hovering = false;
      }
    },
    handleMouseEnter() {
      if (!this.hovering) {
        this.hovering = true;
      }
    },
    addLevel(before?: string) {
      const newLevel = before ? parseInt(before) : Object.keys(this.selected ?? {}).length + 1;

      if (before) {
        const newSelected: Record<string, ViewLabel[]> = {};
        const entries = Object.entries(this.selected);
        entries.forEach(([level, labels]) => {
          if (parseInt(level) < newLevel) {
            newSelected[level] = labels;
          } else if (parseInt(level) === newLevel) {
            newSelected[level] = [];
            newSelected[parseInt(level) + 1] = labels;
          } else {
            newSelected[parseInt(level) + 1] = labels;
          }
        });
        this.selected = newSelected;
      } else if (newLevel) {
        this.selected[newLevel] = [];
      }
    },
    resetUI() {
      this.toggledSection = "configure";
      if (this.viewLabels && Object.keys(this.viewLabels)?.length === 0) {
        this.structureMissing = true;
        this.editMode = true;
        this.selected = { 1: [] };
      } else {
        this.editMode = false;
        this.selected = viewLabelsToStructure(this.viewLabels);
      }
    },
    clearLevel(level: string) {
      this.selected[parseInt(level)] = [];
      this.clearModalOpen = undefined;
    },
    removeLevel(level: string) {
      if (parseInt(level) === this.maxLevel) {
        delete this.selected[parseInt(level)];
      }
    },
    labelsForLevel(level: string) {
      return [...(this.selected?.[parseInt(level)] || [])].sort((a, b) => a.text.localeCompare(b.text));
    },
    toggleSection(section: string) {
      if (this.toggledSection === section) {
        this.toggledSection = "";
        store.dispatch("rightNavbarOpen", false);
      } else {
        store.dispatch("rightNavbarOpen", true);
        this.toggledSection = section;
      }
    },
    handleLabelClose(label: ViewLabel) {
      this.selected[label.level] = this.selected[label.level]?.filter((x) => x.text !== label.text) ?? [];
    },
    saveStructure() {
      const viewLabelsNew = [] as ViewLabel[];
      Object.entries(this.selected).forEach(([level, labels]) => {
        labels.forEach((label: ViewLabel) => {
          label.level = parseInt(level);
          viewLabelsNew.push(label);
        });
      });
      this.store.dispatch("labels/saveViewLabels", {
        viewId: this.viewId,
        viewLabels: viewLabelsNew,
      });
      this.editMode = false;
    },
    toggleModal() {
      if (this.saveModalOpen) {
        this.newAnalysis = undefined;
        this.saveDetails = {} as AnalysisDetails;
      }
      this.saveModalOpen = !this.saveModalOpen;
    },
    handleSave(details: AnalysisDetails) {
      const payload = { ...this.saveDetails, ...details };
      let action = "analysis/createOrUpdateAnalysis";
      if (this.saveType === "View") {
        action = payload.id ? "analysis/updateView" : "analysis/createView";
      }
      this.store.dispatch(action, payload);
      this.newAnalysis = undefined;
      this.saveDetails = {} as AnalysisDetails;
    },
    userHasOwnerAccess(analysisOrView?: Analysis | View) {
      const user = this.store.state.user;
      return analysisOrView?.user_id === user.id || user.type === "company_admin";
    },
    clearItemToShare() {
      this.shareData = undefined;
    },
  },
  components: {
    ActionIcon,
    CreateAnalysisFilterModal,
    MaireButton,
    MaireChip,
    MaireDialog,
    LabelFilters,
    ShareItemModal,
  },
});
</script>
