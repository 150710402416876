<template>
  <div class="company_preferences">
    <DiscoverySearch />
  </div>
  <v-container>
    <div v-show="isLoading" class="wait-animation">
      <wait-animation />
    </div>
    <div v-show="!isLoading" id="main-analysis-view">
      <div class="drawer" v-show="!isLoading">
        <div
          class="inline-block btn"
          style="display: inline-block; width: 70%; margin-left: 24px; position: relative"
        ></div>
        <div class="drawer-content" :class="{ active: drawersOpen.includes('graphs') }">
          <v-row>
            <v-col
              :cols="widget.col"
              :md="6"
              :sm="12"
              :lg="6"
              :xl="widget.col"
              :key="widget.name"
              v-for="widget in widgets"
            >
              <MaireChart
                :name="widget.name"
                :id="widget.id"
                :precalculatedStats="widget.precalculatedStats"
                :volumes="widget.volumes"
                :timelineStats="timelineStats"
                :dataDateRange="dataDateRange"
                :initialType="widget.type"
                :defaultField="widget.defaultField"
                :fields="widget.fields"
                :label="widget.name"
                :loading="!!widget.processing"
                :level="widget.level"
                :defaultTimeframeText="widget.defaultTimeframeText"
              >
                <div id="prediction-button" v-if="widget.name === 'Timeline'">
                  <MaireButton
                    :compact="true"
                    @click="fetchTimelinePredictions"
                    text="Fetch predictions"
                    :isLoading="!!widget.processing"
                  /></div
              ></MaireChart>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="!isLoading">
        <v-col cols="12" class="container-margin">
          <div>
            <div class="title-and-search">
              <div class="mb-3 title-text"></div>
              <div class="search_container">
                <SearchBar />
              </div>
            </div>
            <div v-show="!isLoading">
              <keyword-table :keywords="filteredKeywords" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
#prediction-button {
  width: 150px;
}
#main-analysis-view {
  margin-top: 50px;
}
.center-info {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
}

.center-info p {
  text-align: center;
}
.information-paper {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  max-width: 600px;
  padding: 24px;
  background-color: white;
  border-radius: 0px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
}
.drawer-content {
  display: none;
  margin-top: 24px;
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}

.title-text {
  margin-bottom: 36px;
  padding-bottom: 20px !important;
  width: 70%;
  display: inline-block;
}
.search_container {
  width: 30%;
  float: right;
  position: relative;
  display: block;
}

.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts">
// import KeywordFilters from "@/components/KeywordFilters/KeywordFilters.vue";
import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import DiscoverySearch from "@/components/DiscoverySearch/DiscoverySearch.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { GrowthStat, useWorker } from "@/composables/worker";
import MaireChart from "@/components/MaireChart/MaireChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { filterKeywords } from "@/components/helpers/helpers";
import { WidgetType, useStore } from "@/store";
import { useRouter } from "vue-router";
import { Keyword } from "@/store/modules/keywords/types";
import { Ref, computed, defineComponent, ref, watch, onMounted, onUnmounted } from "vue";
import * as api from "@/api";
export default defineComponent({
  setup() {
    const store = useStore();
    const { startWorker, terminateWorker } = useWorker();
    onUnmounted(() => {
      terminateWorker();
    });

    if (store.state.user?.type === "viewer") {
      const router = useRouter();
      router.push({ path: "analysis" });
    }

    const loadingTimelineData = ref(false);
    const timelineStats = ref([]);
    const fetchTimelinePredictions = () => fetchKwStats(true);
    const fetchKwStats = async (predictions?: boolean) => {
      if (filteredIds.value.length === 0) {
        return;
      }
      loadingTimelineData.value = true;
      const r = await api.fetchKwStats(filteredIds.value, predictions);
      loadingTimelineData.value = false;
      timelineStats.value = r.data;
    };
    const isLoading = computed(() => store.state.loading.loadingKeywords);
    const drawersOpen = ref(["graphs"] as string[]);
    const keywords = computed(() => store.state.keywords.discoveryKeywords);

    const filters = computed(() => store.state.analysis.currentFilters);
    const filteredKeywords = computed(() => {
      const kws = filterKeywords(keywords.value, filters.value, false, {}) as Array<Keyword>;
      return kws;
    });
    const filteredIds = computed(() => filteredKeywords.value.map((kw) => kw.id));

    const keywordStats: Ref<GrowthStat[]> = ref([]);

    const processing: Ref<boolean> = ref(true);
    const widgets = computed(() => {
      const newKwStats = keywordStats.value.filter((stat) => stat.isNew);
      const oldKwStats = keywordStats.value.filter((stat) => !stat.isNew);
      const oldKws = filteredKeywords.value.filter((kw) => !kw.isNew);
      const oldKwStatsMap = Object.fromEntries(oldKws.map((kw) => [kw.keyword, kw.volumes]));
      return [
        {
          id: 6,
          type: WidgetType.TimelineChart,
          name: "Timeline",
          timelineData: timelineStats.value,
          defaultField: "",
          fields: [],
          processing: loadingTimelineData.value,
          col: 4,
        },
        {
          id: 3,
          type: WidgetType.BarChart,
          name: "Trending by keyword",
          defaultTimeframeText: "Latest",
          precalculatedStats: oldKwStats,
          volumes: oldKwStatsMap,
          defaultField: "trending",
          fields: [{ key: "trending", value: "Trending (% of last 12 months peak)" }],
          processing: processing.value,
          hide: oldKwStats.length === 0,
          col: 4,
          level: "keyword",
        },
        {
          id: 55,
          type: WidgetType.BarChart,
          name: "Volume by keyword",
          defaultTimeframeText: "Last 12 months",
          precalculatedStats: oldKwStats,
          volumes: oldKwStatsMap,
          defaultField: "volume",
          fields: [{ key: "volume", value: "Search volume" }],
          processing: processing.value,
          hide: oldKwStats.length === 0,
          col: 4,
          level: "keyword",
        },
        {
          id: 4,
          type: WidgetType.BarChart,
          name: "Growth by keyword",
          defaultTimeframeText: "Last 12 months",
          precalculatedStats: oldKwStats,
          volumes: oldKwStatsMap,
          defaultField: "growth",
          fields: [{ key: "growth", value: "Growth" }],
          processing: processing.value,
          hide: oldKwStats.length === 0,
          col: 4,
          level: "keyword",
        },
        {
          id: 5,
          type: WidgetType.BarChart,
          precalculatedStats: newKwStats,
          defaultField: "lastOneMonthVolume",
          fields: [
            { key: "volume", value: "Average monthly volume" },
            { key: "lastOneMonthVolume", value: "Last month's volume" },
          ],
          processing: processing.value,
          hide: newKwStats.length === 0,
          name: "New keywords",
          col: 6,
          level: "keyword",
        },
      ].filter((widget) => !widget.hide);
    });

    let prevProps: any = {};
    const dataDateRange = ref({
      firstYear: 0,
      firstMonth: 0,
      latestYear: 0,
      latestMonth: 0,
    });
    const processLabelStats = () => {
      const kwIds = filteredIds.value;
      if (prevProps?.kwIds === kwIds) {
        return;
      }
      prevProps = {
        kwIds,
      };
      processing.value = true;
      const clonedKws = JSON.parse(JSON.stringify(filteredKeywords.value));
      if (clonedKws?.length > 0) {
        // This should be triggered whenever the labels, or selected keywords change. However the API doesn't support this yet
        const startTask = () => {
          const data = {
            keywords: clonedKws,
            labels: [],
          };
          startWorker(data, (result: any) => {
            keywordStats.value = result.keywordStats;
            dataDateRange.value = {
              firstYear: result.firstYear,
              firstMonth: result.firstMonth,
              latestYear: result.latestYear,
              latestMonth: result.latestMonth,
            };
            processing.value = false;
          });
        };
        startTask();
      } else {
        processing.value = false;
        keywordStats.value = [];
      }
    };

    watch(
      filteredKeywords,
      (newValue, oldValue) => {
        const oldIds = new Set(oldValue?.map((kw) => kw.id) || []);
        // set of new ids
        const newIds = new Set(newValue?.map((kw) => kw.id));
        if (oldIds.size !== newIds.size) {
          fetchKwStats();
          processLabelStats();
        } else {
          // same size so we need to check if the ids are the same
          for (const id of oldIds) {
            if (!newIds.has(id)) {
              fetchKwStats();
              processLabelStats();
              return;
            }
          }
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      store.dispatch("selectDynamicFilter");
    });
    return {
      isLoading,
      dataDateRange,
      filteredKeywords,
      processing,
      drawersOpen,
      widgets,
      timelineStats,
      fetchTimelinePredictions,
      filterKeywords,
    };
  },
  components: {
    DiscoverySearch,
    KeywordTable,
    MaireButton,
    MaireChart,
    SearchBar,
    WaitAnimation,
  },
});
</script>
