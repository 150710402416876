<template>
  <v-container v-if="isLoading">
    <v-row class="text-start">
      <v-col cols="12">
        <div class="animation">
          <wait-animation
            :height="90"
            :width="90"
            :message="
              isLoadingArticle ? 'Maire is busy writing your article.' : 'We are getting your titles ready'
            "
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-if="!isLoading">
    <v-row class="breadcrumbs">
      <v-col cols="6">
        <p @click="gotoMyKeywords" class="inline pointer maire-violet">My analyses</p>
        <p class="inline maire-violet">/</p>
        <p @click="gotoKeywordAnalysis" class="inline pointer maire-violet">{{ keyword?.keyword }}</p>
        <p class="inline maire-violet">/</p>
        <p @click="gotoArticlePrompts" class="inline pointer maire-violet">article prompts</p>
        <p class="inline maire-violet">/</p>
        <p class="inline pointer maire-dark-blue">article preview</p>
      </v-col>
      <v-col cols="6" class="float-right export-buttons">
        <v-btn
          :loading="isGeneratingZip"
          :disabled="!!!article.sections"
          class="plain-btn inline inverse"
          @click="downloadZip"
        >
          <span v-if="!isGeneratingZip">DOWNLOAD ZIP</span>
          <v-progress-circular
            v-if="isGeneratingZip"
            size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
        <v-btn :disabled="!!!article.sections" class="plain-btn inline inverse" @click="copyToClipboard">
          <span>COPY TEXT</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="split-view-editor" cols="5">
        <!-- <div :class="`section ${article.title ? 'filled' : ''}`">
          <v-row>
            <v-col cols="2" class="plain-title">TEMPLATE</v-col>
            <v-col cols="10" class="plain-title">
              <ContentTypeSelector :disable="true" variant="select" />
            </v-col>
          </v-row>
        </div>   -->

        <div id="ask-maire-anything-section">
          <h3 id="ask-maire-anything-title">Ask Maire Anything</h3>
          <p id="ask-maire-anything-body">
            You might want to expand on a certain topic that you think Maire hasn’t touched on enough. Or it
            might be a subtitle that you have a specific question about. Sky’s the limit.
          </p>
          <div id="chat" styl="margin-top:-50px;">
            <Chat />
          </div>
        </div>
        <div :class="`section image-selector ${article.imagesApproved ? 'filled' : ''}`">
          <h3 id="ask-maire-anything-title">Create more images</h3>
          <ImageSelector hideSidebar />
        </div>
        <div>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6" v-if="false">
              <MaireButton
                @click="generateArticle"
                :disabled="!article.title || !article.subtitles || !article.abstract"
                text="+ RE-GENERATE ARTICLE"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="split-view-preview" cols="7">
        <ArticlePreview />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
div#chat {
  position: relative;
  height: 400px;
}
#ask-maire-anything-body {
  padding-bottom: 12px;
}

.image-selector {
  padding-right: 12px;
  padding-top: 50px;
}

.export-buttons {
  right: 12px !important;
}

.split-view-editor {
  padding-top: 24px !important;
  height: 80vh;
  overflow-y: scroll;
  padding-right: 30px;
}

.split-view-preview {
  padding-left: 20px !important;
  margin-top: -42px;
  height: 80vh;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #e500ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e500ff;
}

.animation {
  position: absolute;
  width: 100%;
  margin: auto;
  margin-left: 100px;
  margin-top: 150px;
}

.label {
  margin-bottom: 20px;
}

/* .v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
} */

.inverse {
  background-color: #8200ff !important;
  color: white !important;
}

.inverse:hover {
  color: white !important;
}

#article-button {
  position: fixed;
  width: 300px;
  right: 140px;
  top: 400px;
  padding-right: 40px;
}

.animation {
  position: fixed;
  width: 60%;
  padding: 100px;
  z-index: 100;
}

h3 {
  font-size: 24px;
  line-height: 24px;
  font-family: "Nunito Sans", extrabold;
  margin-bottom: 30px;
}

h4 {
  font-size: 18px;
  line-height: 18px;
  font-family: "Nunito Sans", extrabold;
  margin-right: 30px;
}

.cta {
  margin-right: -20px;
  color: #3e54d3;
}

.section {
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
  padding-left: 0px;
}

.section .title {
  color: #8200ff;
  font-size: 16px;
  line-height: 24px;
}

.structure-title {
  margin-bottom: 21px;
}

.structure-subtitle {
  margin-left: 35px;
}

.breadcrumbs p {
  padding: 5px;
}

.breadcrumbs {
  margin-bottom: 40px;
  margin-top: 30px;
}

.pointer {
  cursor: pointer;
}

.checkmark {
  color: #b500ff;
  margin-bottom: 10px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import VueScrollTo from "vue-scrollto";
import ImageSelector from "@/components/ArticleGeneration/ImageSelector/ImageSelector.vue";
import ArticlePreview from "@/components/ArticleGeneration/ArticlePreview/ArticlePreview.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Chat from "./Chat.vue";
import { BACKEND_PATH } from "@/helpers";
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router"

export default defineComponent({
  data: () => ({
    publicPath: process.env.BASE_URL,
    imageUrl: undefined,
    isGeneratingZip: false,
    questionForMaire: "",
  }),
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    return { store, router, route };
  },
  methods: {
    generateArticle() {
      this.store.dispatch("article/generateArticle", this.article);
    },
    scrollTo(section: string) {
      VueScrollTo.scrollTo(section);
    },
    gotoMyKeywords() {
      this.router.push("/keywords");
    },
    gotoKeywordAnalysis() {
      if (this.keyword?.id) {
        this.router.push(`/keywords/${this.keyword.id}`);
      }
    },
    gotoArticlePrompts() {
      if (this.keyword?.id) {
        this.router.push(`/keywords/${this.keyword.id}/article`);
      }
    },
    async downloadFromUrl(url: string) {
      const response = await axios
        .get(`${BACKEND_PATH}/images?url=${encodeURIComponent(url)}`, {
          responseType: "blob",
        })
        .then((r) => r.data)
        .catch((e) => e);
      return response;
    },
    async downloadZip() {
      this.isGeneratingZip = true;
      const zip = new JSZip();
      zip.file("Article.txt", this.generateArticleBlob());
      if (this.article?.images) {
        const imageUrls = this.article.images.map((img) => img.imageSrc);
        const promises = imageUrls.map((imgUrl) => this.downloadFromUrl(imgUrl));
        const data = await Promise.all(promises);
        const imgFolder = zip.folder("images");
        data.forEach((imgData, idx) => {
          imgFolder?.file(`Image ${idx + 1}.png`, imgData, { base64: true });
        });
      }
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "article.zip");
      });
      this.isGeneratingZip = false;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.generateArticleBlob()).then(
        () => {
          this.store.dispatch("sendSuccessMessage", "Copied!");
        },
        () => {
          this.store.dispatch("sendErrorMessage", "Copying to clipboard failed");
        }
      );
    },
    generateArticleBlob() {
      const rows = [
        [`# ${this.article.title}`],
        ["\n"],
        [`${this.article.abstract}`],
        ["\n"],
        ...(this.article.sections?.flatMap((section) => [
          [`## ${section.title}`],
          ["\n"],
          [section.text],
          ["\n"],
          ["\n"],
        ]) as unknown as any),
      ];
      return rows.map((e) => e.join(",")).join("\n");
    },
  },
  // components: { WaitAnimation, TitleSelector, AbstractEditor, ImageSelector, StructureEditor, ArticlePreview, MaireButton, ContentTypeSelector },
  components: { WaitAnimation, ImageSelector, ArticlePreview, MaireButton, Chat },
  mounted() {
    if (!this.keyword?.keyword || this.keyword?.keyword != this.article.keyword) {
      this.store.dispatch("keywords/getKeywordDetails", { kw_id: this.route.params.id }).then(() => {
        this.store.dispatch("article/generateTitleSuggestions", { keyword: this.keyword?.keyword });
      });
    } else if (!this.article.titleSuggestions) {
      this.store.dispatch("article/generateTitleSuggestions", { keyword: this.keyword?.keyword });
    }
  },
  computed: {
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    isLoading() {
      return this.store.state.loading.loadingKeywords || this.store.state.loading.loadingArticle;
    },
    isLoadingArticle() {
      return this.store.state.loading.loadingArticle;
    },
    article() {
      return this.store.state.article;
    },
  },
});
</script>
