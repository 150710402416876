
import { defineComponent, ref } from "vue";
import { store } from "@/store";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireChip from "@/components/MaireChip/MaireChip.vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import { CheckboxState } from "@/store/modules/analysis/types";
import { useStore } from "@/store"

const __default__ = defineComponent({
  name: "LabelFilters",
  props: {
    selectedLevel: {
      type: Number,
      required: true,
      default: 1,
    },
    overrideRecommendedLevel: {
      type: Boolean,
      required: true,
    },
    changeLevel: {
      type: Function,
      required: true,
    },
    cancelOverride: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
    overflowing: false,
    hoveringOn: "",
    labelFilterContainer: ref(null),
    filterHeight: "3000px",
    elHeight: 0,
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    showMore() {
      return this.elHeight > 800;
    },
    searchWord() {
      return this.store.state.analysis.currentFilters.searchWord;
    },
    includedFilter() {
      return this.store.state.analysis.currentFilters.labelFilters.include;
    },
    includeFilterLabelsByLevel() {
      const res = this.includedFilter.labels.reduce(
        (acc: Record<number, CheckboxState[]>, label: CheckboxState) => {
          if (label.level) {
            if (!acc[label.level]) {
              acc[label.level] = [];
            }
            acc[label.level].push(label);
          }
          return acc;
        },
        {}
      );
      return Object.entries(res);
    },
  },
  watch: {
    expanded() {
      const el = this.$refs.labelFilterContainer as HTMLElement;
      if (el) {
        this.filterHeight = `${el.scrollHeight}px`;
      }
    },
  },
  methods: {
    getIconForLevel(level: string) {
      if(this.overrideRecommendedLevel && this.selectedLevel === parseInt(level)) {
        return "fa-thumbtack"
      }
      return this.hoveringOn === level
        ? "fa-thumbtack"
        : this.selectedLevel === parseInt(level)
        ? "fa-eye"
        : "fa-eye-slash";
    },
    allLabelsSelectedInLevel(level: number) {
      return this.store.state.analysis.currentFilters.labelFilters.include.labels
        .filter((label) => label.level === level)
        .every((label: CheckboxState) => label.checked);
    },
    toggleLabelDrawer() {
      this.expanded = !this.expanded;
    },
    selectAllLabels(level: number) {
      if (this.allLabelsSelectedInLevel(level)) {
        store.dispatch("analysis/deselectAllLabels", level);
      } else {
        store.dispatch("analysis/selectAllLabels", level);
      }
    },
    numberFormatter,
    handleClick(cb: CheckboxState) {
      store.dispatch("analysis/toggleLabelFilter", { cb });
    },
  },
  components: {
    MaireChip,
    MaireButton,
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5e678145": (_ctx.filterHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__