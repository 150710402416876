<template>
  <div class="image-selector-image">
    <MaireDialog
      :onClose="() => (deletionModalOpen = false)"
      :open="deletionModalOpen"
      text="Are you sure you want to delete this image?"
      :fn="() => removeImage(image)"
    />
    <Transition>
      <div v-if="zoomed" class="bg" @click="zoomed = false">
        <div class="zoomedImage">
          <img class="" :src="image.imageSrc" />
          <v-icon @click="zoomed = true" class="ai-image-close"> mdi:mdi-close-circle</v-icon>
        </div>
      </div>
    </Transition>
    <div class="ai-image" style="padding: 0px" tile>
      <img @click="zoomed = true" :src="image.imageSrc" />
      <v-icon @click="deletionModalOpen = true" class="ai-image-close"> mdi:mdi-close-circle</v-icon>
    </div>
  </div>
</template>
<style scoped>
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.zoomedImage {
  max-height: 600px;
  position: relative;
  margin: auto;
  outline: rgb(var(--v-theme-mairePurple)) 3px solid;
}

img {
  width: 100px;
  height: 100px;
}

.zoomedImage img {
  height: 100%;
  margin-bottom: -8px;
  aspect-ratio: 1792/1024;
  width: 800px;
}

.bg {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  min-width: 100vh;
  min-height: 100vh;
  z-index: 9999;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  justify-content: center;
}

.ai-image {
  text-align: center;
  width: 100%;
  min-height: 100%;
  aspect-ratio: 1792/1024;
  border: 2px #b500ff solid;
  border-radius: 0px;
  background-color: rgba(229, 0, 255, 0.016);
  cursor: pointer;
  position: relative;
}

.ai-image:hover {
  scale: 1.05;
}

.ai-image-close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #b500ff;
  cursor: pointer;
}

.ai-image-add {
  position: relative;
  margin: auto;
  margin-top: 45%;
  color: #b500ff;
  cursor: pointer;
}

.ai-image img {
  width: 100%;
  height: 100%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";

export default defineComponent({
  props: {
    hideContent: { type: Boolean, default: false },
    image: { type: Object, required: true },
    removeImage: { type: Function, default: () => {} },
  },
  data: () => ({
    deletionModalOpen: false,
    zoomed: false,
  }),
  mounted() {
    window.addEventListener("keyup", this.handleKeyboardModalClose);
  },
  unmounted() {
    window.removeEventListener("keyup", this.handleKeyboardModalClose);
  },
  methods: {
    handleKeyboardModalClose(event: any) {
      if (event.code === "Escape") {
        this.zoomed = false;
        this.deletionModalOpen = false;
      }
    },
  },
  components: { MaireDialog },
});
</script>
