<template>
  <div v-if="!isLoading">
    <v-row v-if="variant !== 'select'" class="content-type-selector">
      <div
        class="content-card inline"
        :class="category === selectedContentType ? `selected ${getPageCategory(category as string)}` : `${getPageCategory(category as string)}`"
        :v-bind="category"
        v-for="category in categories"
        :key="(category as string)"
        @click="() => setSelectedType(category as string)"
      >
        <span
          v-if="category === selectedContentType"
          :class="`outline inline ${getPageCategory(category as string)}`"
        ></span>
        <span class="inline">{{ category }}</span>
        <img
          class="type_img inline"
          :src="`${publicPath}images/${getPageCategory(category as string)}.png`"
        />
      </div>
    </v-row>
    <v-select
      :disabled="!!disable"
      v-if="variant === 'select'"
      variant="outlined"
      :items="categories"
      v-model="selectedContentType"
    />
  </div>
</template>

<style scoped>
span {
  font-size: 14px;
  font-weight: 800;
  font-family: "Nunito Sans", extrabold;
  color: white;
  margin-right: 30px;
}

.outline {
  position: absolute;
  margin-left: -20px;
  margin-bottom: -100px;
  bottom: 90px;
  height: 3px;
  width: 100%;
}

img {
  vertical-align: middle;
  max-height: 35px;
}

.content-card {
  position: relative;
  background: gray;
  margin-right: 8px;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.content-type-selector {
  position: relative;
  width: 100%;
  overflow: visible;
  white-space: nowrap;
  margin-bottom: 50px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store"
export default defineComponent({
  name: "IntentSelector",
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  props: {
    variant: { required: false, type: String },
    disable: { required: false, type: Boolean, default: false },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    setSelectedType(category: string) {
      this.store.dispatch("article/selectContentType", category);
      this.store.dispatch("article/generateTitleSuggestions", {
        keyword: this.store.state.keywords.keywordWithDetails?.keyword,
      });
    },
    getPageCategory(category: string) {
      if (category == "Guide") {
        return "Illustrative";
      } else if (category == "Explanation") {
        return "Explanatory";
      } else if (category == "Reasoning") {
        return "Reasoning";
      } else if (category == "Statistics") {
        return "Statistical";
      } else if (category == "Product / Service -page") {
        return "Product";
      } else if (category == "Review") {
        return "Evaluatory";
      } else {
        return "Reasoning";
      }
    },
  },
  computed: {
    isLoading() {
      return this.store.state.loading.loadingKeywords;
    },
    selectedContentType() {
      return this.store.state.article.contentType;
    },
    categories() {
      return this.store.state.article.contentTypes;
    },
  },
});
</script>
