<template>
  <div class="article-view">
    <v-container v-if="isLoading">
      <v-row class="text-start">
        <v-col cols="12">
          <div class="animation">
            <wait-animation
              :height="90"
              :width="90"
              :message="
                isLoadingArticle ? 'Maire is busy writing your article.' : 'We are getting your titles ready'
              "
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!isLoading">
      <v-row class="breadcrumbs">
        <p @click="gotoMyKeywords" class="inline pointer maire-violet">Analyses</p>
        <p class="inline maire-violet">/</p>
        <p @click="gotoKeywordAnalysis" class="inline pointer maire-violet">
          {{ keyword?.keyword }}
        </p>
        <p class="inline maire-violet">/</p>
        <p class="inline pointer maire-dark-blue">article prompts</p>
      </v-row>
      <v-row>
        <h3>Your template</h3>
      </v-row>
      <v-row>
        <content-type-selector :selected="contentType" />
      </v-row>
      <v-row class="">
        <v-col cols="8">
          <notification-card
            message="With your invaluable help, Maire will create content to engage and activate your customers when they are searching for this keyword. Your input is a necessity every step of the way to maximize the quality and accuracy of the content. Let’s start by generating the most interesting title possible."
          />
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <MaireButton
            :fixed="true"
            @click="generateArticle"
            :disabled="!article.title || !article.subtitles || !article.abstract"
            text="+ GENERATE ARTICLE"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <div :class="`section ${article.title ? 'filled' : ''}`">
            <TitleSelector />
          </div>
          <div :class="`section ${article.abstractApproved ? 'filled' : ''}`">
            <AbstractEditor />
          </div>
          <div :class="`section ${article.imagesApproved ? 'filled' : ''}`">
            <ImageSelector :hideContent="!article.abstractApproved" />
          </div>
          <div :class="`section subtitle-section ${article.imagesApproved ? 'filled' : ''}`">
            <StructureEditor />
          </div>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.article-view {
  margin-left: 12px;
}
.animation {
  position: absolute;
  width: 100%;
  margin: auto;
  margin-left: 100px;
  margin-top: 150px;
}

.label {
  margin-bottom: 20px;
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.subtitle-section {
  margin-bottom: 400px !important;
}

.inverse {
  background-color: #8200ff !important;
  color: white !important;
}

.animation {
  position: fixed;
  width: 60%;
  padding: 100px;
  z-index: 100;
}

h3 {
  font-size: 24px;
  line-height: 24px;
  font-family: "Nunito Sans", extrabold;
  margin-bottom: 30px;
}

h4 {
  font-size: 18px;
  line-height: 18px;
  font-family: "Nunito Sans", extrabold;
  margin-right: 30px;
}

.cta {
  margin-right: -20px;
  color: #3e54d3;
}

.section {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: rgba(229, 0, 255, 0.016);
  color: black;
  padding: 20px;
  padding-left: 20px;
}

.filled {
  background-color: rgba(229, 0, 255, 0.04);
}

.section .title {
  color: #8200ff;
  font-size: 16px;
  line-height: 24px;
}

.structure-title {
  margin-bottom: 21px;
}

.structure-subtitle {
  margin: 21px;
  margin-left: 35px;
}

.breadcrumbs p {
  padding: 5px;
}

.breadcrumbs {
  margin-bottom: 40px;
  margin-top: 30px;
}

.pointer {
  cursor: pointer;
}

.checkmark {
  color: #b500ff;
  margin-bottom: 10px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import ContentTypeSelector from "@/components/ContentTypeSelector/ContentTypeSelector.vue";
import VueScrollTo from "vue-scrollto";
import NotificationCard from "@/components/NotificationCard/NotificationCard.vue";
import TitleSelector from "@/components/ArticleGeneration/TitleSelector/TitleSelector.vue";
import AbstractEditor from "@/components/ArticleGeneration/AbstractEditor/AbstractEditor.vue";
import ImageSelector from "@/components/ArticleGeneration/ImageSelector/ImageSelector.vue";
import StructureEditor from "@/components/ArticleGeneration/StructureEditor/StructureEditor.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  data: () => ({
    publicPath: process.env.BASE_URL,
    imageUrl: undefined,
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  methods: {
    generateArticle() {
      if (this.keyword?.id) {
        this.store.dispatch("article/generateArticle", this.article);
        this.router.push(`/keywords/${this.keyword.id}/article/preview`);
      }
    },
    scrollTo(section: string) {
      VueScrollTo.scrollTo(section);
    },
    gotoMyKeywords() {
      this.router.push("/keywords");
    },
    gotoKeywordAnalysis() {
      if (this.keyword?.id) {
        this.router.push(`/keywords/${this.keyword.id}`);
      }
    },
  },
  components: {
    WaitAnimation,
    MaireButton,
    ContentTypeSelector,
    NotificationCard,
    TitleSelector,
    AbstractEditor,
    ImageSelector,
    StructureEditor,
  },
  watch: {
    keyword(newVal, oldVal) {
      if (newVal != oldVal) {
        this.store.dispatch("article/generateTitleSuggestions", {
          keyword: this.keyword?.keyword,
        });
      }
    },
  },
  mounted() {
    if (!this.keyword?.keyword || this.keyword.keyword != this.article.keyword) {
      this.store.dispatch("keywords/getKeywordDetails", {
        kw_id: this.route.params.id,
      });
    } else if (!this.article.titleSuggestions) {
      this.store.dispatch("article/generateTitleSuggestions", {
        keyword: this.keyword?.keyword,
      });
    }
    if (this.contentType) {
      this.store.dispatch("article/selectContentType", this.contentType);
    }
  },
  computed: {
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    isLoading() {
      return this.store.state.loading.loadingKeywords || this.store.state.loading.loadingArticle;
    },
    isLoadingArticle() {
      return this.store.state.loading.loadingArticle;
    },
    article() {
      return this.store.state.article;
    },
    contentType() {
      return this.route.params.contentType;
    },
    favoritedKeywords() {
      return this.store.state.user.favorited_keywords;
    },
  },
});
</script>
