import { ref, onBeforeUnmount } from "vue";

export interface GrowthStat {
  label: string;
  growth: number;
  absoluteGrowth: number;
  growthRolling?: number;
  volume: number;
  lastOneMonthVolume?: number;
  trending: number;
  isNew?: boolean;
  count?: number;
}

export const useWorker = () => {
  const workerRef = ref<Worker | null>(null);
  const isBusy = ref(false);

  const startWorker = (data: any, onCompletion: (result: any) => void) => {
    if (isBusy.value && workerRef.value) {
      workerRef.value.terminate();
      workerRef.value = null;
    }
    if (!workerRef.value) {
      workerRef.value = new Worker(new URL("@/composables/StatsWorker.tsx", import.meta.url), {
        type: "module",
        name: "StatsWorker"
      });
      workerRef.value.onmessage = (event: MessageEvent) => {
        const { action, data, error } = event.data;
        if (error) {
          console.error("WORKER: Worker Error:", error);
        } else if (action === "calculateLabelStats") {
          onCompletion(data);
        }
        isBusy.value = false; // Reset the busy state when an error occurs
      };
      workerRef.value.onerror = (event: ErrorEvent) => {
        console.error("WORKER: Worker Error:", event.message);
        isBusy.value = false; // Reset the busy state when an error occurs
      };
    }
    workerRef.value.postMessage({
      action: "calculateLabelStats",
      data,
    });
    isBusy.value = true; // Reset the busy state when an error occurs
  };

  const terminateWorker = () => {
    if (workerRef.value) {
      workerRef.value.terminate();
      workerRef.value = null;
      isBusy.value = false; // Reset the busy state when the worker is terminated
    }
  };

  // Clean up on unmount
  onBeforeUnmount(() => {
    terminateWorker();
  });

  return {
    startWorker,
    terminateWorker,
  };
};
